<mat-card>

    <h1>Select inventory to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">

            <div class="half">
                <common-form-hub-select
                        label="Hub from"
                        [required]="true"
                        [value]="old_hub.value"
                        (onGroupSelected)="available_hubs = $event"
                        (onChange)="old_hub.setValue($event)"></common-form-hub-select>
            </div>

            <div class="half" *ngIf="available_hubs">
                <common-form-hub-select
                        label="Hub to"
                        [required]="true"
                        [value]="form.value.inventory_conversion_id"
                        [options_grouped]="available_hubs"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <div class="full">
                <div class="margin-bottom-20">
                    <mat-chip-listbox>
                        <mat-chip-option matChipRemove *ngFor="let ref of form.value.boxes"
                                         [removable]="true"
                                         (removed)="removeBoxChip(ref)">
                            {{ ref }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                    </mat-chip-listbox>
                </div>
                <mat-form-field class="full">
                    <mat-label>Select boxes</mat-label>
                    <input type="text" matInput [formControl]="boxSearch" (keydown)="onKeydown($event)">
                </mat-form-field>

                <div>
                    Boxes selected: {{ form.value.boxes.length }}<sup>*</sup>
                </div>
                <div>
                    <small>
                        <sup>*</sup>Although there is no limit, we do not recommend moving more than 100 boxes at a
                        time as this may cause processing delays
                    </small>
                </div>
            </div>

            <div class="full color-warn text-center">
                <p *ngFor="let e of errors">
                    {{ e }}
                </p>
            </div>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
                <textarea row="5" matInput formControlName="remark"></textarea>
            </mat-form-field>

        </div>


        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid || errors.length > 0">
                Update
            </button>
        </div>
    </form>

</mat-card>
