import {ChangeDetectionStrategy, Component, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {take} from "rxjs/operators";

@Component({
    selector: "common-alert",
    template: `
        <ng-template>
            <div class="common-alert">
                <div class="text-center margin-bottom-20">
                    <mat-icon color="warn" class="md-48">warning</mat-icon>
                    <span *ngIf="title" style="font-size: 130%"><br>{{ title }}</span>
                </div>

                <div class="mat-body text-center margin-bottom-20" [innerHTML]="text"></div>

                <div class="text-center">
                    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">{{ buttonText }}</button>
                </div>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AlertComponent {

    /**
     * Config
     * @type {MatDialogConfig}
     */
    private config: MatDialogConfig = {
        width: "400px",
        height: "190px"
    };

    @ViewChild(TemplateRef, {static: false})
    public alertTemplateRef: TemplateRef<any>;

    /**
     * Dialog component reference
     * @type {MatDialogRef<boolean>}
     */
    public dialogRef: MatDialogRef<boolean>;

    /**
     * Text
     * @type {string}
     */
    public text: string = "";

    public title: string = null;

    public buttonText: string = "OK";

    public constructor(public matDialog: MatDialog) {
    }

    /**
     * Show alert
     * @param {string} text
     * @param {string} title
     * @param {string} buttonText
     * @returns {Promise<boolean> | boolean}
     */
    public show(text: string, title: string = null, buttonText: string = "OK"): Promise<boolean> | boolean {
        if (text) {
            this.text = text;
            this.title = title;
            this.buttonText = buttonText;
            this.dialogRef = this.matDialog.open(this.alertTemplateRef, this.config);
            return new Promise((resolve: Function): void => {
                this.dialogRef.beforeClosed().pipe(take(1)).subscribe((value: boolean): void => resolve(value));
            });
        }

        return true;
    }

}
