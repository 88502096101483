import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ModalService} from "../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../procedures";
import {BoxSticker2Component, BoxStickerComponent} from "..";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-warehouse-boxes-list",
    template: `

        <h1 class="container-heading">Boxes</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                    <div data-actions>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseBoxesListComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "list",
                    title: "Items"
                },
                {
                    name: "print",
                    title: "Print sticker"
                },
                {
                    name: "print-alt",
                    title: "Print sticker"
                }
            ],
            api: this.apiService.getUrl(["box"]) + "?data_structure=dataTables",
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
                {
                    data: "warehouse_package.name",
                    name: "WarehousePackage.name",
                    title: "Package"
                },
                {
                    data: "inventories_count",
                    title: "Items",
                    searchable: false
                },
                {
                    data: "full",
                    title: "Full",
                    searchable: false,
                    render: (data: number): string => {
                        return data ? "Yes" : "No";
                    }
                },
            ],
            order: [],
            search: true
        };

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "list") {
            await this.modalService.open(BoxItemsListComponent, {
                box: action.data,
                modalWidth: 1000,
                itemsType: "warehouse-transactions"
            });
        } else if (action.name === "print") {
            await this.modalService.open(BoxStickerComponent, {
                box: action.data
            });
        } else if (action.name === "print-alt") {
            await this.modalService.open(BoxSticker2Component, {
                box: action.data,
                warehouseName: action.data.warehouse_location.warehouse.name,
                locationName: action.data.warehouse_location.location
            });
        }
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.prepareList();
        this.spinnerService.hide();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-boxes",
            actions: {
                "browse": ["browse_boxes"]
            }
        };
    }
}
