import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ContactListComponent} from "../../contact";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {UserService} from "../../../../../../common/services/user.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {LinkPartMastersToAddressComponent, LinkServiceLevelsToAddressComponent} from "../index";
import {AddressService} from "../../../../../../common/services/address.service";
import {INCOTERMS} from "../../../../../../common/constants/incoterms-list.constant";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {ServiceLevelService} from "../../../../../../common/services/service-level.service";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Api3Service} from "../../../../../../common/services/api3.service";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-address-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AddressFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        address_type: new FormControl(null),
        country: new FormControl(null),
        address: new FormControl(null),
        address2: new FormControl(null),
        city: new FormControl(null),
        state: new FormControl(null),
        zip: new FormControl(null),
        ref: new FormControl(null),
        ref2: new FormControl(null),
        address_name: new FormControl(null),
        lat: new FormControl(null),
        lng: new FormControl(null),
        note: new FormControl(null),
        outbound_hub_id: new FormControl(null),
        inbound_hub_id: new FormControl(null),
        inventory_conversion_id: new FormControl(null),
        out_incoterms: new FormControl(null),
        in_incoterms: new FormControl(null),
        default_service_level_id: new FormControl(null),
        default_courier_service_id: new FormControl(null),
        is_visible: new FormControl(true),
        delivery_ref: new FormControl(true),
    });

    public countries: any[];

    public modal: Modal.IModal;

    public action: string = "add";

    public addressAcModel: any = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "short_name",
        postal_code: "short_name"
    };

    public incoterms: ISelectOption[] = INCOTERMS;

    public showAddressAc: boolean = false;

    public serviceLevels: ISelectOption[] = [];

    public courierServices: { value: number, name: string }[] = [];

    public courierServicesFiltered: ReplaySubject<{ value: number, name: string }[]> =
        new ReplaySubject<{ value: number, name: string }[]>(1);

    public selectSearch: FormControl = new FormControl(null);

    public remarkTypes: { name: string, value: any }[] = [];

    public noteUsage = [
        "Main", "Remarks", "Custom Fields", "Pick List",
        "Packing List/Invoice", "Label Creation"
    ];

    public conditionalParameters = [
        {
            usage: "Remarks",
            formControlName: "remark_type",
            label: "Remark Type",
            type: "select",
            options: []
        }
    ];

    public addressNotes: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private helpers: HelpersService,
        private modalService: ModalService,
        private userService: UserService,
        private addressService: AddressService,
        private serviceLevelService: ServiceLevelService,
        private spinnerService: SpinnerService,
        private api3Service: Api3Service
    ) {
    }

    private async getValidationConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.modal.params.state.section}/forms-validation-rules/address`,
        );
        this.spinnerService.hide();

        if (data) {
            HelpersService.setValidatorsFromConfig(this.formGroup, data);
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Prepare body for submit
     * @returns {any}
     */
    private prepareBody(): any {
        return {
            ...this.formGroup.value,
            contact: [
                {
                    ...this.formGroup.value.contact,
                    customer: this.formGroup.value.customer
                }
            ],
            customer_id: this.modal.params.customer?.id || null
        };
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    private async getServiceLevels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.serviceLevelService.getServiceLevels();
        if (data) {
            this.serviceLevels = this.serviceLevelService.mapServiceLevelsToSelect(data);
        }
        this.spinnerService.hide();
    }

    private async getCourierServices(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["courier_service"],
            {}, {data_structure: "select"});
        if (response.data) {
            this.courierServices = response.data;
            this.courierServicesFiltered.next(this.courierServices.slice());
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.conditionalParameters[0].options = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {

        const data: any = this.modal.params.data ? this.modal.params.data : {};

        if (this.modal && this.modal.params.type) {
            data.address_type = this.modal.params.type;
        } else if (this.modal.params.data && this.modal.params.data.address_type) {
            data.address_type = this.modal.params.data.address_type.toLowerCase();
        } else {
            data.address_type = "main";
        }

        if (!data.id && this.modal.params.customer) {
            data.delivery_ref = this.modal.params.customer.delivery_ref;
        }

        data.outbound_hub_id = data.outbound_inventory_conversions
        && data.outbound_inventory_conversions.length
            ? data.outbound_inventory_conversions[0].id : null;

        data.inbound_hub_id = data.inbound_inventory_conversions
        && data.inbound_inventory_conversions.length
            ? data.inbound_inventory_conversions[0].id : null;

        data.country = data.country_iso_2;

        this.formGroup.patchValue(data);

        this.addressNotes = data.notes;

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get address data
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.addressService.getAddress(this.modal.params.data.id, {
            relations: [
                "OutboundInventoryConversions",
                "InboundInventoryConversions",
                "Notes"
            ]
        });
        this.spinnerService.hide();

        if (data) {
            this.modal.params.data = data;
            this.prepareForm();
        }
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onCountrySelected($event: any): void {
        this.formGroup.get("country").setValue($event.value);
    }

    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            if (this.action === "add") {
                this.apiService.setHeaders({Partner: this.modal.params.partner});
                const body: any = this.prepareBody();
                const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, "address", body);
                this.spinnerService.hide();
                if (data) {
                    this.toastService.show(message, "success");
                    if (this.modal.params.canEdit) {
                        this.modal.params.data = {id: data.fields[0].data[0].value};
                        this.getData();
                        this.action = "edit";
                        this.modal.params.canDelete = true;
                        this.changeDetectorRef.markForCheck();
                    } else {
                        if (Array.isArray(data.fields) && data.fields[0] &&
                            Array.isArray(data.fields[0].data) && data.fields[0].data[0]) {
                            const value: any = data.fields[0].data[0];
                            this.modal.response.next({
                                name: "value",
                                value: {
                                    controlValue: value.value,
                                    viewValue: value.name,
                                    refValue: this.formGroup.value.country
                                }
                            });
                        }
                    }
                }
            } else if (this.action === "edit") {
                const body = this.formGroup.value;
                body.customer_id = this.modal.params.customer?.id || null;

                const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                    ["address", this.modal.params.data.id], this.formGroup.value);
                this.spinnerService.hide();
                if (response) {
                    this.toastService.show(response.message, response.type);
                    if (response.data) {
                        if (!this.modal.params.canEdit) {
                            this.modal.response.next({
                                name: "value",
                                value: response.data
                            });
                        }
                        this.showList();
                    }
                }
            }
        }
    }


    /**
     * Delete address
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current address?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["address", this.modal.params.data.id], {
                    exclude_instance: "customer",
                    exclude_instance_id: this.modal.params.customer.id
                });
            this.spinnerService.hide();
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.showList();
            }
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public showList(): void {
        this.modal.response.next({
            name: "list"
        });
    }


    /**
     * Show contacts list
     */
    public async showContacts(): Promise<any> {
        await this.modalService.open(ContactListComponent, {
            address: this.modal.params.data.id,
            canEdit: true,
            canDelete: true
        });
    }

    /**
     * Show linked PartMasters
     */
    public async showPMs(): Promise<any> {
        await this.modalService.open(LinkPartMastersToAddressComponent, {
            address_id: this.modal.params.data.id,
            state: this.modal.params.state
        });
    }

    /**
     * Show linked Service levels
     */
    public async showSLs(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(LinkServiceLevelsToAddressComponent, {
            address_id: this.modal.params.data.id,
            state: this.modal.params.state
        });

        if (response.value) {
            this.getData();
        }
    }

    public async checkCoords(): Promise<any> {
        this.spinnerService.show();
        const selectedCountry = this.countries.find(c => c.value === this.formGroup.controls["country"].value);
        const addressParts = [
            selectedCountry.name,
            this.formGroup.controls["state"].value,
            this.formGroup.controls["city"].value,
            this.formGroup.controls["address"].value
        ];
        const address = addressParts.filter((s: string) => s && s.trim()).join(", ");
        const {data}: Api.IResponse = await this.addressService.getCoordinatesByAddress(address);
        this.spinnerService.hide();

        if (!data) {
            return;
        }

        const diffCoefficient = 1.0;
        const isWrongLat = Math.abs(data.lat - this.formGroup.controls["lat"].value) > diffCoefficient;
        const isWrongLng = Math.abs(data.lng - this.formGroup.controls["lng"].value) > diffCoefficient;
        if (isWrongLat || isWrongLng) {
            if (await this.confirmRef.confirm(
                "Lat and lng address parameters are wrong. Change them to correct parameters?"
            )) {
                this.formGroup.patchValue(data);
            }
        } else {
            this.toastService.show("Lat and lng parameters are correct", "success");
        }
    }

    public onAddressAutocompleteResult(event: { place: any, model: any, lat: number, lng: number }): void {
        this.formGroup.get("country").setValue(event.model.country);
        this.formGroup.get("address").setValue(event.model.street_number + ", " + event.model.route);
        this.formGroup.get("city").setValue(event.model.locality);
        this.formGroup.get("state").setValue(event.model.administrative_area_level_1);
        this.formGroup.get("zip").setValue(event.model.postal_code);
        this.formGroup.get("lat").setValue(event.lat);
        this.formGroup.get("lng").setValue(event.lat);
        this.changeDetectorRef.markForCheck();
    }

    public async onSaveNote(note: any): Promise<void> {
        const addressId = this.modal?.params?.data?.id;
        this.spinnerService.show();
        const noteId = note.id;
        if (noteId) {
            const { data }: Api.IResponse = await this.api3Service.put(
                `${this.modal.params.state.section}/addresses/${addressId}/notes/${noteId}`,
                note
            );
        } else {
            const { data }: Api.IResponse = await this.api3Service.post(
                `${this.modal.params.state.section}/addresses/${addressId}/notes`,
                {
                    notes: [note]
                }
            );
        }
        this.spinnerService.hide();
    }

    public async onDeleteNote(note: any): Promise<void> {
        const addressId = this.modal?.params?.data?.id;
        const noteId = note.id;
        if (noteId) {
            this.spinnerService.show();
            await this.api3Service.delete(
                `${this.modal.params.state.section}/addresses/${addressId}/notes/${noteId}`
            );
            this.spinnerService.hide();
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.action = this.modal && this.modal.params.action ? this.modal.params.action : "add";
        this.getValidationConfig();
        this.getServiceLevels();
        this.getCourierServices();
        this.prepareCountriesList().then((): void => {
            if (this.modal.params.getData) {
                this.getData();
            } else {
                this.prepareForm();
            }
        });
        this.getRemarkTypes();

        if (this.modal.params.state) {
            const [type, slug]: string[] = this.modal.params.state.section.split("/");
            if (slug) {
                const [partner]: User.IPartner[] = this.userService.data.partners
                    .filter((pn: User.IPartner): boolean => pn.slug === slug);
                this.showAddressAc = partner && partner.properties.address_autocomplete;
                this.changeDetectorRef.markForCheck();
            }
        }

        this.selectSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (!value) {
                    this.courierServicesFiltered.next(this.courierServices.slice());
                } else {
                    value = value.toLowerCase();
                    this.courierServicesFiltered.next(
                        this.courierServices.filter((service: { name: string }): boolean =>
                            service.name.toLowerCase().indexOf(value) > -1)
                    );
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    protected readonly Validators = Validators;
}
