import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {FormControl} from "@angular/forms";

@Component({
    selector: "section-inspection-results",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Inspections results</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <common-form-date-range
                            (valueChange)="setDatePeriod($event)"
                        ></common-form-date-range>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InspectionResultsComponent implements Base.IComponent, OnInit {

    private partnerColumns: Table.ICol[] = [];

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public section: string;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public date_range_filter: FormControl = new FormControl(null);

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private async getColumns(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inspection", "form"], {}, {data_structure: "datatables_columns"});
        if (data) {
            this.partnerColumns = data;
        }
        this.prepareList();
        this.spinnerService.hide();
    }

    private getUrl(): Table.ITableApi {
        return {
            url: [this.state.section, "inspections", "results"],
            query: {
                relations: [
                    "User:id,name",
                    "Inspection:id,name",
                    "Partner:id,icon_path"
                ],
                date_range: this.date_range_filter.value
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];

        if (this.userService.validatePermissions("browse_inspection_forms")) {
            actions.push({
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        this.state.component,
                        "view",
                        "id",
                        row._id
                    ]);
                }
            });
        }

        if (this.userService.validatePermissions("edit_inspections")) {
            actions.push({
                name: "edit",
                title: "Edit",
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "inspection-results",
                        "edit",
                        "id",
                        row._id
                    ]);
                }
            });
        }

        if (this.userService.validatePermissions("delete_inspections")) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: async (row: any): Promise<any> => {
                    if (await this.confirmRef.confirm(
                        `Do you want to delete inspection${row.ref ? " " + row.ref : ""}?`)) {
                        this.spinnerService.show();
                        const {data, message}: Api.IResponse =
                            await this.api3Service.delete(`${this.state.section}/inspections/results/${row._id}`);
                        this.spinnerService.hide();
                        if (data) {
                            this.toastService.show(message, "success");
                            this.tableRef.reload();
                        }
                    }
                }
            });
        }

        const columns: Table.ICol[] = [
            {
                data: "pdf_url",
                title: "Pdf",
                render: (row: any): string => {
                    return row.pdf_url ? "<a href='" + row.pdf_url + "' target='_blank'" +
                        " class='mat-icon material-icons'>picture_as_pdf</a>" : "";
                },
                sortable: false,
                searchable: false
            },
        ];

        const extra_export_columns: any[] = [];

        if (this.state.section_type !== "partner") {
            columns.push({
                data: "partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return "<img src='" + row.partner.icon_path + "' alt=''>";
                },
                sortable: false,
                searchable: false
            });

            extra_export_columns.push({
                data: "Partner.display_name",
                title: "Partner"
            });
        }


        if (this.section === "new_liveu") {
            extra_export_columns.push(
                {
                    data: "field_input_ai_remarks",
                    title: "AI Remarks"
                },
                {
                    data: "status",
                    title: "Inspection status"
                },
            );
        }

        this.listTable = {
            table_id: "YC920euli9A",
            actions,
            api: this.getUrl(),
            search_default: this.state.section_slug === "new_liveu" ? ["ref", "field_input_serial"] : ["ref"],
            columns: columns.concat(this.partnerColumns),
            export: {
                file_name: "inspections",
                columns: [
                    {
                        data: "pdf_url",
                        title: "Pdf"
                    },
                    ...this.partnerColumns,
                    ...extra_export_columns
                ]
            },
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public setDatePeriod(event: string): void {
        this.date_range_filter.setValue(event);
        this.tableRef.reload(this.getUrl());
    }

    public ngOnInit(): void {
        this.getColumns();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspection-results",
            actions: {
                "browse": ["show_inspection_results"]
            }
        };
    }
}
