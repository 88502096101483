<div class="row nowrap stretch margin-bottom-20 flex">
    <mat-card class="col-left">
        <h2 class="border-bottom-none">Scan location</h2>

        <div class="bordered-box margin-bottom-20" [class.bordered-box-active]="!location">
            <table>
                <tr>
                    <td width="60">
                        <i class="fa fa-barcode fa-4x text-success"></i>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%">
                            <mat-label>Location</mat-label>
                            <input matInput [formControl]="searchLocation">
                        </mat-form-field>
                    </td>
                    <td width="110">
                        <button type="button" mat-raised-button color="success"
                                [disabled]="!searchLocation.value"
                                (click)="findLocation()">
                            <i class="fa fa-barcode"></i>
                            Search
                        </button>
                    </td>
                </tr>
            </table>

            <div class="text-center">
                Your scanner default key set as {{ scannerKey }}.
                <span *ngIf="scannerOnly">Manual input disabled</span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <button type="button" mat-raised-button color="primary" (click)="locationList()">
                Available locations
            </button>
            <button type="button" mat-raised-button color="primary" class="pull-right" (click)="addLocation('')">
                Add location
                <mat-icon>add</mat-icon>
            </button>
            <div class="clear"></div>
        </div>

        <div class="margin-bottom-10">
            <button type="button" mat-raised-button color="primary" (click)="findLocationByItem()">
                Location by item
            </button>

            <button type="button" mat-raised-button color="accent" class="pull-right"
                    *ngIf="warehouse && warehouse.ulockit_lockers.length"
                    (click)="showLockers()">
                Put in locker
                <mat-icon>table</mat-icon>
            </button>
            <div class="clear"></div>
        </div>


        <div class="bordered-box margin-bottom-20">
            <div>
                <table>
                    <tr>
                        <td width="30">
                            <mat-icon>location_city</mat-icon>
                        </td>
                        <td>
                            <h2>Selected location</h2>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b *ngIf="location" class="text-success" style="font-size: 140%;">
                                {{ location.name }}
                                <span *ngIf="location.hub">({{ location.hub.customers_sub_inventory }})</span>
                            </b>
                            <b *ngIf="!location" class="color-warn" style="font-size: 140%;">
                                not selected
                            </b>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <table>
                    <tr>
                        <td width="30">
                            <mat-icon>location_city</mat-icon>
                        </td>
                        <td>
                            <h2>Used locations</h2>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div *ngIf="usedLocations">
                                <table *ngFor="let location of usedLocations" class="bordered">
                                    <tr>
                                        <td>
                                            {{ location.location }}
                                            <span *ngIf="location.hub">({{ location.hub.sub_inventory }})</span>
                                        </td>
                                        <td width="40">
                                            <div (click)="setLocationFromList(location)" class="pointer chipsa">
                                                Select
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="flex row align-center space-between margin-bottom-20">
            <mat-form-field class="max margin-right-10">
                <mat-label>Customs Number</mat-label>
                <input matInput [formControl]="customsNumber">
            </mat-form-field>

            <div>
                <button type="button" mat-raised-button color="primary" (click)="updateCustomsNumber()">Save</button>
            </div>
        </div>


        <div>
            <button mat-raised-button type="button" color="primary" class="margin-bottom-10" (click)="addTracking()">
                Add tracking
                <mat-icon>share_location</mat-icon>
            </button>
        </div>

        <div *ngIf="courier_transactions" class="margin-bottom-20">
            <div *ngFor="let track of courier_transactions" class="margin-bottom-10">
                <img [src]="track.courier.icon_path" alt="" width="40" class="vertical-middle">
                &nbsp;
                {{ track.courier.display_name }} -
                <span class="color-accent">{{ track.tracking_number }}</span>
                ({{ track.status }})
                <span *ngIf="track.status !== 'delivered'"
                      class="icon-compose vertical-middle pointer"
                      matTooltip="Mark as delivered"
                      (click)="markDelivered(track.id)">
                    <mat-icon class="main">local_shipping</mat-icon>
                    <mat-icon class="additional text-success"
                              style="right:8px;bottom: 7px">check_circle</mat-icon>
                    </span>
            </div>
        </div>
    </mat-card>

    <mat-card class="col-center max margin-right-10 margin-left-10">
        <h2 class="border-bottom-none">Scan item</h2>

        <div class="bordered-box margin-bottom-20" [class.bordered-box-active]="location">
            <div *ngIf="!location" class="text-center">
                <h2 class="color-warn">Select location first</h2>
            </div>
            <table class="search-field" *ngIf="location">
                <tr>
                    <td width="60">
                        <i class="fa fa-barcode fa-4x text-success"></i>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%">
                            <mat-label>Item, serial, box, pallet/ob</mat-label>
                            <input matInput type="search"
                                   [formControl]="searchInput"
                                   [scannerOnly]="scannerOnly"
                                   (keydown)="onKeydown($event)" #searchInputRef>
                        </mat-form-field>
                    </td>
                    <td width="110">
                        <button type="button" mat-raised-button color="success" (click)="find()"
                                [disabled]="!searchInput.value">
                            <i class="fa fa-barcode"></i> Search
                        </button>
                    </td>
                </tr>
            </table>
            <div class="text-center">
                Your scanner default key set as {{ scannerKey }}.
                <span *ngIf="scannerOnly" class="color-warn">Manual input disabled</span>
            </div>

        </div>


        <div class="bordered-box">

            <div class="flex row align-center">
                <h2 class="max border-bottom-none">
                    Order items list ({{ originalItemsData?.total_items }})
                </h2>

                <mat-form-field class="half">
                    <mat-label>Search by pallet/ob, boxes, items</mat-label>
                    <input type="text" matInput [formControl]="inboundItemsSearch">
                </mat-form-field>
            </div>


            <div class="clear margin-bottom-20">
                <div class="pull-right">
                    <mat-slide-toggle [formControl]="showMatchedInList">
                        Show fully scanned items
                    </mat-slide-toggle>
                </div>

                <div class="totals">
                    <div class="chipsa">
                        <mat-icon class="vertical-middle">pallet</mat-icon>
                        {{ totalToScan.pallets }} pallets/obs
                    </div>
                    <div class="chipsa">
                        <mat-icon class="vertical-middle">inventory_2</mat-icon>
                        {{ totalToScan.boxes }} boxes
                    </div>
                    <div class="chipsa">
                        <mat-icon class="vertical-middle">category</mat-icon>
                        {{ totalToScan.items }} items
                    </div>
                </div>
            </div>

            <div class="scroll-list">
                <div *ngFor="let pallet of orderRows.pallets | search: inboundItemsSearch.value: ['ref']"
                     [class.hidden]="!showMatchedInList.value && pallet.matched"
                     class="margin-bottom-10 item">
                    <table>
                        <tr>
                            <td width="30">
                                <mat-icon class="vertical-middle">pallet</mat-icon>
                            </td>
                            <td>
                                Pallet/ob: <b>{{ pallet.ref }}</b>
                            </td>
                            <td class="text-right">
                                <div *ngIf="pallet.matched" style="display:inline-block" class="vertical-middle">
                                    <div class="chipsa background-success">
                                        <mat-icon>check_circle</mat-icon>
                                        Scanned
                                    </div>
                                </div>
                                &nbsp;
                                <mat-icon class="pointer vertical-middle" matTooltip="Show pallet/ob contents"
                                          (click)="openPallet(pallet)">
                                    fact_check
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngFor="let box of orderRows.boxes  | search: inboundItemsSearch.value: ['ref']"
                     [class.hidden]="!showMatchedInList.value && box.matched"
                     class="margin-bottom-10 item">
                    <table>
                        <tr>
                            <td width="30">
                                <mat-icon class="vertical-middle">inventory_2</mat-icon>
                            </td>
                            <td>
                                Box: <b>{{ box.ref }}</b>
                            </td>
                            <td class="text-right vertical-middle">
                                <div *ngIf="box.matched" style="display:inline-block" class="vertical-middle">
                                    <div class="chipsa background-success">
                                        <mat-icon>check_circle</mat-icon>
                                        Scanned
                                    </div>
                                </div>
                                &nbsp;
                                <mat-icon class="pointer vertical-middle" matTooltip="Show box contents"
                                          (click)="openBox(box)">
                                    fact_check
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngFor="let item of orderRows.items  | search: inboundItemsSearch.value: ['item','serial']"
                     [class.hidden]="!showMatchedInList.value && item.matched"
                     class="margin-bottom-10 item">
                    <table>
                        <tr>
                            <td width="30" class="vertical-top">
                                <mat-icon>category</mat-icon>
                                <mat-icon (click)="printSticker(item)" style="background-color: yellow;"
                                          title="Print stickers">print
                                </mat-icon>
                            </td>
                            <td class="vertical-top">
                                <div>
                                    Item: <b>{{ item.item }}</b>
                                </div>
                                <div *ngIf="item.serial">
                                    Serial: <b>{{ item.serial }}</b>
                                </div>
                                <div *ngIf="item.batch">
                                    Batch: <b>{{ item.batch }}</b>
                                </div>
                                <div *ngIf="item.rev">
                                    Revision: <b>{{ item.rev }}</b>
                                </div>
                                <div *ngIf="item.lot">
                                    Lot: <b>{{ item.lot }}</b>
                                </div>

                                <div *ngIf="!item.serial">
                                    Qty:
                                    <b>
                                        {{ item.quantity - item.matched_qty }}
                                        <ng-container *ngIf="item.matched_qty > 0">
                                            ({{ item.quantity }})
                                        </ng-container>
                                    </b>
                                </div>
                                <div>
                                    Target hub:
                                    <b>
                                        {{ item.inventory_conversion.customers_inventory_name }} -
                                        {{ item.inventory_conversion.customers_sub_inventory }}
                                    </b>
                                </div>
                            </td>
                            <td class="vertical-bottom text-right">
                                <div style="display:inline-block" *ngIf="item.matched_qty > 0">
                                    <div class="chipsa"
                                         [class.background-success]="item.matched_qty === item.quantity"
                                         [class.background-danger]="item.matched_qty !== item.quantity">
                                        <mat-icon>
                                            {{ item.matched_qty === item.quantity ? "check_circle" : "error" }}
                                        </mat-icon>
                                        Scanned: {{ item.matched_qty }}
                                        &nbsp;
                                        &nbsp;
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>

    </mat-card>

    <mat-card class="col-right">
        <h2 class="border-bottom-none">Scan feed</h2>

        <div class="bordered-box">

            <div class="text-right">
                <button mat-raised-button type="button" color="warn"
                        *ngIf="checkedFeedItems.length"
                        (click)="deleteScans(checkedFeedItems)">
                    Delete selected
                </button>
                &nbsp;
                <mat-form-field>
                    <mat-label>Search by pallet/ob, boxes, items</mat-label>
                    <input type="text" matInput #feedSearch>
                </mat-form-field>
            </div>

            <div class="margin-bottom-10">
                <mat-checkbox [formControl]="showOnlyFailedScans">
                    Show only failed scans
                </mat-checkbox>


                <mat-checkbox [formControl]="selectAll" matTooltip="Select all scans"
                              class="pull-right margin-right-20"></mat-checkbox>
            </div>

            <div class="totals clear margin-bottom-20">
                <div class="chipsa">
                    <mat-icon class="vertical-middle">pallet</mat-icon>
                    {{ totalScanned.pallets }} pallets/obs
                </div>
                <div class="chipsa">
                    <mat-icon class="vertical-middle">inventory_2</mat-icon>
                    {{ totalScanned.boxes }} boxes
                </div>
                <div class="chipsa">
                    <mat-icon class="vertical-middle">category</mat-icon>
                    {{ totalScanned.items }} items
                </div>
            </div>

            <mat-tab-group>
                <mat-tab label="All">
                    <div class="scroll-list clear" style="max-height:750px;">
                        <div *ngIf="scanFeed.length === 0">
                            <p class="text-center">
                                Feed is empty
                            </p>
                        </div>
                        <ng-container
                                *ngFor="let feed_item of scanFeed | search: feedSearch.value:['item','box','serial','pallet']">
                            <div class="margin-bottom-10 item"
                                 *ngIf="!showOnlyFailedScans.value || showOnlyFailedScans.value && feed_item.status ==='error'">
                                <table>
                                    <tr>
                                        <td width="30" rowspan="2" class="vertical-top">
                                            <div *ngIf="feed_item.status==='pending'; else scantype">
                                                <mat-icon class="status pending">settings</mat-icon>
                                            </div>
                                            <ng-template #scantype>
                                                <mat-icon *ngIf="feed_item.type==='pallet'">pallet</mat-icon>
                                                <mat-icon *ngIf="feed_item.type==='box'">inventory_2</mat-icon>
                                                <mat-icon *ngIf="feed_item.type==='item'">category</mat-icon>
                                                <mat-icon *ngIf="feed_item.type==='serial'">category</mat-icon>
                                            </ng-template>
                                        </td>
                                        <td class="vertical-top">
                                            <div *ngIf="feed_item.type==='pallet'">
                                                Pallet/ob: <b>{{ feed_item.pallet }}</b>
                                            </div>

                                            <div *ngIf="feed_item.type==='box'">
                                                Box: <b>{{ feed_item.box }}</b>
                                            </div>

                                            <ng-container *ngIf="feed_item.type==='item' || feed_item.type==='serial'">
                                                <div>
                                                    Item: <b>{{ feed_item.item }}</b>
                                                </div>

                                                <div *ngIf="feed_item.serial">
                                                    Serial: <b>{{ feed_item.serial }}</b>
                                                </div>

                                                <div *ngIf="feed_item.batch">
                                                    Batch: <b>{{ feed_item.batch }}</b>
                                                </div>

                                        <div *ngIf="feed_item.rev">
                                            Revision: <b>{{ feed_item.rev }}</b>
                                        </div>

                                        <div *ngIf="feed_item.lot">
                                            Lot: <b>{{ feed_item.lot }}</b>
                                        </div>

                                                <div *ngIf="feed_item.quantity > 1">
                                                    Qty: <b>{{ feed_item.quantity }}</b>
                                                </div>
                                            </ng-container>

                                            <div>
                                                Location: {{ feed_item.location }}
                                            </div>

                                        </td>
                                        <td width="30" class="text-center vertical-top">
                                            <mat-checkbox
                                                    [checked]="checkedFeedItems.includes(feed_item.feed_id)"
                                                    (change)="$event.checked ? selectScan(feed_item.feed_id): deSelectScan(feed_item.feed_id)">
                                            </mat-checkbox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div *ngIf="feed_item.message"
                                                 class="chipsa"
                                                 [class.background-success]="feed_item.status==='success'"
                                                 [class.background-danger]="feed_item.status==='error'">
                                                <div>
                                                    <mat-icon>
                                                        {{ feed_item.status === 'success' ? "check_circle" : "error" }}
                                                    </mat-icon>
                                                </div>
                                                <div>
                                                    {{ feed_item.message }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <mat-icon class="status color-warn pointer"
                                                      matTooltip="Delete scan"
                                                      (click)="deleteScans([feed_item.feed_id])">
                                                delete
                                            </mat-icon>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab label="Grouped">
                    <div class="scroll-list clear" style="max-height:750px;">
                        <div *ngIf="scanFeedGrouped.length === 0">
                            <p class="text-center">
                                Feed is empty
                            </p>
                        </div>
                        <div *ngFor="let feed_group of scanFeedGrouped">
                            <div class="margin-bottom-10 padding-right-10 padding-left-10 background-success location flex row space-between align-center ">
                                <b class="max padding-top-5 padding-bottom-5">Location: {{ feed_group.location }}</b>
                            </div>

                            <div class="margin-bottom-10 item" *ngIf="feed_group.pallets.length > 0">
                                <table>
                                    <tr>
                                        <td width="30" class="vertical-top">
                                            <div>
                                                <mat-icon>pallet</mat-icon>
                                            </div>
                                        </td>
                                        <td class="vertical-top">
                                            Pallets QTY: {{ feed_group.pallets.length }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <mat-icon class="vertical-middle pointer"
                                                          (click)="showScansFromLocation(feed_group.pallets)">
                                                    fact_check
                                                </mat-icon>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="chipsa background-success">
                                                <div>
                                                    <mat-icon>check_circle</mat-icon>
                                                </div>
                                                <div>
                                                    Total {{ feed_group.pallets_quantity }} item(s) ready to inbound
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="margin-bottom-10 item" *ngIf="feed_group.boxes.length > 0">
                                <table>
                                    <tr>
                                        <td width="30" class="vertical-top">
                                            <div>
                                                <mat-icon>inventory_2</mat-icon>
                                            </div>
                                        </td>
                                        <td class="vertical-top">
                                            Boxes QTY: {{ feed_group.boxes.length }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <mat-icon class="vertical-middle pointer"
                                                          (click)="showScansFromLocation(feed_group.boxes)">
                                                    fact_check
                                                </mat-icon>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="chipsa background-success">
                                                <div>
                                                    <mat-icon>check_circle</mat-icon>
                                                </div>
                                                <div>
                                                    Total {{ feed_group.boxes_quantity }} item(s) ready to inbound
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="margin-bottom-10 item" *ngIf="feed_group.items.length > 0">
                                <table>
                                    <tr>
                                        <td width="30" class="vertical-top">
                                            <div>
                                                <mat-icon>category</mat-icon>
                                            </div>
                                        </td>
                                        <td class="vertical-top">
                                            Items QTY: {{ feed_group.items.length }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <mat-icon class="vertical-middle pointer"
                                                          (click)="showScansFromLocation(feed_group.items)">
                                                    fact_check
                                                </mat-icon>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="chipsa background-success">
                                                <div>
                                                    <mat-icon>check_circle</mat-icon>
                                                </div>
                                                <div>
                                                    Total {{ feed_group.items_quantity }} item(s) ready to inbound
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card>
</div>

<mat-card>
    <div>
        <div class="text-left">
            <div class="color-accent margin-bottom-10">
                Press "Finish scan" button to complete scanning procedure.
                Only valid scans will be converted to transactions.
            </div>
            <button mat-raised-button color="accent" (click)="completeScanning()">
                Finish scan
            </button>
        </div>
    </div>
</mat-card>

<common-confirm></common-confirm>