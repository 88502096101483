<div *ngIf="showOverlay" id="sidebar_overlay" (click)="isOpen = false; onClose.emit()"></div>
<div id="sidebar" class="{{isOpen ? 'open' : ''}} theme-grey">
    <div id="sb_flag" (click)="isOpen = false">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
    <div class="content">
        <h2>Remarks</h2>

        <div>
            <button *ngIf="userService.validatePermissions('add_order_remarks')"
                    mat-raised-button color="primary" (click)="resetForm(); showForm = 'full'">
                Add remark
            </button>
            &nbsp;
            <button *ngIf="state.section_type === 'partner'"
                    mat-raised-button color="primary" (click)="addWhInstructions();showForm = 'full'">
                Warehouse Instructions
            </button>

            <ng-container>
                &nbsp;
                <input type="text" #iTicketRef style="position: absolute; left: -9999px;">
                <button type="button" mat-raised-button color="accent" [matMenuTriggerFor]="iTicketsMenu">
                    <mat-icon>content_copy</mat-icon>
                    Copy ITicket
                </button>
                <mat-menu #iTicketsMenu="matMenu">
                    <a mat-menu-item *ngFor="let option of remarkTypes"
                       (click)="copyITicket(option.id)">
                        {{ option.name }}: {{ makeHashId(option.id) }}
                    </a>
                </mat-menu>
            </ng-container>

            <form class="remark-form margin-top-10" [@slideInOut]="showForm"
                  *ngIf="formGroup" [formGroup]="formGroup" (submit)="submitRemark()">
                <div class="padding-top-20 padding-bottom-20 padding-left-20 padding-right-20">
                    <div class="group flex">
                        <mat-form-field class="half">
                            <mat-label>Title</mat-label>
                            <input matInput [formControlName]="'subject'">
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>
                                <mat-icon>remove_red_eyes</mat-icon>
                                who can see
                            </mat-label>
                            <mat-select [formControlName]="'order_remark_type_id'"
                                        required>
                                <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Text</mat-label>
                            <textarea matInput [formControlName]="'message'"
                                      matTextareaAutosize matAutosizeMinRows="2"
                                      matAutosizeMaxRows="5" required mentions [mentionUsers]="mentionUsers"></textarea>
                        </mat-form-field>
                        <div class="half">
                            <mat-checkbox [formControlName]="'emergency'">
                                Urgent
                            </mat-checkbox>
                        </div>
                        <div class="half">
                            <mat-checkbox [formControlName]="'followup'">
                                Create followup
                            </mat-checkbox>
                        </div>
                        <mat-form-field *ngIf="formGroup.get('followup').value === true" class="full">
                            <mat-label>Followup Team</mat-label>
                            <mat-select formControlName="followup_team"
                                        required>
                                <mat-option *ngFor="let team of followup_teams" [value]="team.value">
                                    {{ team.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full" *ngIf="remarkClassifications && remarkClassifications.length > 0">
                            <mat-label>Classification</mat-label>
                            <mat-select [formControlName]="'order_remark_classification_id'" required>
                                <mat-option *ngFor="let option of remarkClassifications" [value]="option.id">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="attachments" *ngIf="attachments && attachments.length > 0">
                        <h3>Attachments:</h3>
                        <div class="attachment flex row no-wrap align-center margin-bottom-20"
                             *ngFor="let attachment of attachments; let i = index">
                            <mat-icon class="margin-right-10">{{ getIconByExt(attachment.extension) }}</mat-icon>

                            <a href="{{attachment.name}}" target="_blank" class="margin-right-10" matTooltip="Open">
                                <span>{{ attachment.name }}</span>
                            </a>

                            <mat-icon color="warn" class="pointer" matTooltip="Remove"
                                      (click)="removeAttachment(i, attachment.id)">
                                cancel
                            </mat-icon>
                        </div>
                    </div>

                    <div class="actions">
                        <button mat-raised-button color="primary" type="submit" [disabled]="!formGroup.valid">
                            Submit
                        </button>
                        &nbsp;
                        <button mat-raised-button type="button"
                                (click)="resetForm()">
                            Cancel
                        </button>
                        <button mat-raised-button type="button" (click)="this.addAttachment()">
                            Add attachment
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="remarks" *ngIf="data">
            <div class="padding-top-10">
                <mat-checkbox [formControl]="show_system_remarks">Show system remarks</mat-checkbox>
            </div>

            <mat-form-field class="padding-top-10" *ngIf="remarkClassifications && remarkClassifications.length > 0">
                <mat-label>Classification</mat-label>
                <mat-select [formControl]="order_remark_classification_ids" multiple>
                    <mat-option *ngFor="let option of remarkClassifications" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="pull-right padding-top-10">
                <mat-label>Who can see</mat-label>
                <mat-select [formControl]="order_remark_type_ids" multiple>
                    <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="pull-left padding-top-10">
                <mat-label>Search</mat-label>
                <input matInput type="text" [formControl]="remarkSearch">
            </mat-form-field>

            <div class="clear"></div>

            <ng-container *ngIf="!show_system_remarks.value && !data.data.length">
                <p class="background-accent">
                    Please note, some remarks might be hidden
                </p>
            </ng-container>

            <ng-template ngFor let-remark [ngForOf]="data.data">
                <common-section-remark [data]="remark" [remarkTypes]="remarkTypes" [orderRef]="order_ref"
                                       [maxId]="maxId"
                                       [readOnView]="markRemarkReadOnView"
                                       [mentionUsers]="mentionUsers"
                                       (reloadEvent)="getData()"
                                       (maxIdEvent)="setMaxId($event)"></common-section-remark>
            </ng-template>
            <common-pagination [data]="data"
                               pid="614HGIxgy"
                               (goToPage)="getData($event.page, $event.per_page)"></common-pagination>
        </div>
        <div *ngIf="noData">
            <p>No remarks yet</p>
        </div>

    </div>
</div>