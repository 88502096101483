import {ModuleWithProviders, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {OverlayModule} from "@angular/cdk/overlay";
import {PortalModule} from "@angular/cdk/portal";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ReactiveFormsModule} from "@angular/forms";
import {ConfirmComponent} from "./components/confirm/confirm.component";
import {FieldComponent} from "./components/field/field.component";
import {SpinnerComponent} from "./components/spinner/spinner.component";
import {TableComponent} from "./components/table/table.component";
import {WizardComponent} from "./components/wizard/wizard.component";
import {FixPipe} from "./pipes/fix.pipe";
import {ApiService} from "./services/api.service";
import {GuardService} from "./services/guard.service";
import {LoginService} from "./services/login.service";
import {StorageService} from "./services/storage.service";
import {ToastService} from "./services/toast.service";
import {UserService} from "./services/user.service";
import {DragulaModule} from "ng2-dragula";
import {HelpersService} from "./services/helpers.service";
import {AutocompleteComponent} from "./components/autocomplete/autocomplete.component";
import {UserAllowedDirective} from "./directives/user-allowed.directive";
import {ObjectKeysPipe} from "./pipes/object-keys.pipe";
import {SearchPipe} from "./pipes/search.pipe";
import {ReplacePipe} from "./pipes/replace.pipe";
import {UcFirstPipe} from "./pipes/ucfirst.pipe";
import {HiddenDirective} from "./directives/hidden.directive";
import {FilterPipe} from "./pipes/filter.pipe";
import {LineBreaksPipe} from "./pipes/line-break.pipe";
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import {
    BarChartComponent,
    CalendarChartComponent,
    ComboChartComponent,
    DonutChartComponent,
    GeoChartComponent,
    LineChartComponent
} from "./components/charts";
import {GoogleChartsService} from "./services/google-chart.service";
import {AppLockService} from "./services/app-lock.service";
import {AlertComponent} from "./components/alert/alert.component";
import {ChangeStatusFormComponent} from "./components/status/status.component";
import {MglTimelineModule} from "angular-mgl-timeline";
import {WizardComponentFactoryDirective} from "./directives/wizard-component-factory.directive";
import {PaginationComponent} from "./components/pagination/pagination.component";
import {PusherService} from "./services/pusher.service";
import {NotificationService} from "./services/notification.service";
import {SignaturePadComponent} from "./components/signature/signature.component";
import {JoinPipe} from "./pipes/join.pipe";
import {
    CommonFormBarcodeComponent,
    CommonFormCheckboxComponent,
    CommonFormComponent,
    CommonFormDateComponent,
    CommonFormDatePeriodComponent, CommonFormDateTimeComponent,
    CommonFormEditorComponent, CommonFormErrorComponent,
    CommonFormFieldsComponent,
    CommonFormFileUploadComponent,
    CommonFormSearchComponent,
    CommonFormSelectComponent,
    CommonFormSwitchComponent,
    FormBuilderComponent,
    FormBuilderFieldComponent,
    FormBuilderGroupComponent
} from "./components/form";
import {CounterComponent} from "./components/counter/counter.component";
import {ModalComponent} from "./components/modal/modal.component";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {GmapComponent} from "./components/gmap/gmap.component";
import {SidebarComponent} from "./components/sidebar/sidebar.component";
import {SnackbarComponent} from "./components/snackbar/snackbar.component";
import {QuillModule} from "ngx-quill";
import {FilterComponent} from "./components/filter/filter.component";
import {CommonTestComponent} from "./components/test-server/test-server.component";
import {SortPipe} from "./pipes/sort.pipe";
import {GmapPlacesAutocompleteComponent} from "./components/gmap/places-autocomplete/places-autocomplete.component";
import {Google2faComponent} from "./components/google2fa/google2fa.component";
import {SchedulerFormComponent, SchedulerListComponent} from "./components/scheduler";
import {StrPadPipe} from "./pipes/str-pad.pipe";
import {CommonFormDateRangeComponent} from "./components/form/date/range.component";
import {ItemConfigurationNamesPipe} from "./pipes/item-configuration-names.pipe";
import {ModalTable2Component, Table2Component, TableCellComponent} from "./components/table2";
import {SpinnerService} from "./services/spinner.service";
import {NoScrollDirective} from "./directives/noscroll.directive";
import {ToNumberPipe} from "./pipes/to-number.pipe";
import {Url2linkPipe} from "./pipes/url-to-link.pipe";
import {MentionsDirective, MentionsDropdownComponent} from "./directives/mentions.directive";
import {MentionsPipe} from "./pipes/mentions.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {BarcodeReaderComponent} from "./components/barcode-reader/barcode-reader.component";
import {HubSelectComponent} from "./components/form/hub-select/hub-select.component";
import {UserNotAllowedDirective} from "./directives/user-not-allowed.directive";
import {SimpleSpinnerComponent} from "./components/spinner-simple/spinner.component";
import {OrderService} from "./services/order.service";
import {TransactionService} from "./services/transaction.service";
import {PartMasterService} from "./services/part-master.service";
import {AddressService} from "./services/address.service";
import {ContractService} from "./services/contract.service";
import {ServiceLevelService} from "./services/service-level.service";
import {PartnerService} from "./services/partner.service";
import {ThpeeplService} from "./services/thpeepl.service";
import {WarehouseService} from "./services/warehouse.service";
import {PromptModalComponent} from "./components/prompt/prompt.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {ImageUploadService} from "./services/image-upload.service";
import {CourierService} from "./services/courier.service";
import {ReportService} from "./services/report.service";
import {RequestService} from "./services/request.service";
import {TimelineComponent} from "./components/timeline/timeline.component";
import {ShipmentService} from "./services/shipment.service";
import {BubbleChartComponent} from "./components/charts/bubble/bubble.component";
import {PaginationService} from "./services/pagination.service";
import {HubService} from "./services/hub.service";
import {PickupRequestService} from "./services/pickup-request.service";
import {PrettyJsonPipe} from "./pipes/pretty-json.pipe";
import {AttachmentService} from "./services/attachment.service";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {ScannerOnlyDirective} from "./directives/scanner-only.directive";
import {MilestonesService} from "./services/milestones.service";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRippleModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatChipsModule} from "@angular/material/chips";
import {MatRadioModule} from "@angular/material/radio";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatBadgeModule} from "@angular/material/badge";
import {MatTableModule} from "@angular/material/table";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {GoogleMapsModule} from "@angular/google-maps";
import {BarcodeComponent} from "./components/barcode/barcode.component";
import {LivechatComponent} from "./components/livechat/livechat.component";
import {GoogleMapsApiService} from "./services/google-maps-api.service";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {
    NGX_MAT_DATE_FORMATS,
    NgxMatDateFormats,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {RouterModule} from "@angular/router";
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {AppStateService} from "./services/app-state.service";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSliderModule} from "@angular/material/slider";
import {IfPipe} from "./pipes/if.pipe";
import {StaticMapComponent} from "./components/gmap/static-map.component";
import {ObserveVisibilityDirective} from "./directives/observe-visibility.directive";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MediaScreenService} from "./services/media-screen.service";
import {Api3Service} from "./services/api3.service";
import {BatchService} from "./services/batch.service";
import {TeamService} from "./services/team.service";
import {ControlDirective} from "./directives/control.directive";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {SoundsService} from "./services/sounds.service";
import {AmplitudeService} from "./services/amplitude.service";
import {LoadConfigComponent} from "./components/load-config/load-config.component";
import {ObjectValuesPipe} from "./pipes/object-values.pipe";
import {ModalTableComponent} from "./components/modal/modal-table.component";
import {TimezonePipe} from "./pipes/timezone.pipe";
import {MatSelectRemoveAriaOwnsDirective} from "./directives/select-fix.directive";


export const DATE_FORMATS: NgxMatDateFormats = {
    display: {
        dateInput: "DD.MM.YYYY HH:mm",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "YYYY.MM.DD",
        monthYearA11yLabel: "MMMM YYYY",
    },
    parse: {
        dateInput: "YYYY-MM-DD HH:mm:ss",
    }
};

export const TIME_ZONES: { [key: string]: string } = {
    "Pacific/Midway": "(GMT-11:00) Midway Island",
    "Pacific/Honolulu": "(GMT-10:00) Hawaii",
    "America/Anchorage": "(GMT-09:00) Alaska",
    "America/Los_Angeles": "(GMT-08:00) Pacific Time (US & Canada)",
    "America/Tijuana": "(GMT-08:00) Tijuana",
    "America/Denver": "(GMT-07:00) Mountain Time (US & Canada)",
    "America/Chihuahua": "(GMT-07:00) Chihuahua",
    "America/Mazatlan": "(GMT-07:00) Mazatlan",
    "America/Mexico_City": "(GMT-06:00) Mexico City",
    "America/Monterrey": "(GMT-06:00) Monterrey",
    "Canada/Saskatchewan": "(GMT-06:00) Saskatchewan",
    "America/Chicago": "(GMT-06:00) Central Time (US & Canada)",
    "America/New_York": "(GMT-05:00) Eastern Time (US & Canada)",
    "America/Bogota": "(GMT-05:00) Bogota",
    "America/Lima": "(GMT-05:00) Lima",
    "America/Caracas": "(GMT-04:30) Caracas",
    "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
    "America/La_Paz": "(GMT-04:00) La Paz",
    "America/Santiago": "(GMT-04:00) Santiago",
    "Canada/Newfoundland": "(GMT-03:30) Newfoundland",
    "America/Buenos_Aires": "(GMT-03:00) Buenos Aires",
    "Greenland": "(GMT-03:00) Greenland",
    "Atlantic/Stanley": "(GMT-02:00) Stanley",
    "Atlantic/Azores": "(GMT-01:00) Azores",
    "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde Is.",
    "Africa/Casablanca": "(GMT) Casablanca",
    "Europe/Dublin": "(GMT) Dublin",
    "Europe/Lisbon": "(GMT) Lisbon",
    "Europe/London": "(GMT) London",
    "Africa/Monrovia": "(GMT) Monrovia",
    "Europe/Amsterdam": "(GMT+01:00) Amsterdam",
    "Europe/Belgrade": "(GMT+01:00) Belgrade",
    "Europe/Berlin": "(GMT+01:00) Berlin",
    "Europe/Bratislava": "(GMT+01:00) Bratislava",
    "Europe/Brussels": "(GMT+01:00) Brussels",
    "Europe/Budapest": "(GMT+01:00) Budapest",
    "Europe/Copenhagen": "(GMT+01:00) Copenhagen",
    "Europe/Ljubljana": "(GMT+01:00) Ljubljana",
    "Europe/Madrid": "(GMT+01:00) Madrid",
    "Europe/Paris": "(GMT+01:00) Paris",
    "Europe/Prague": "(GMT+01:00) Prague",
    "Europe/Rome": "(GMT+01:00) Rome",
    "Europe/Sarajevo": "(GMT+01:00) Sarajevo",
    "Europe/Skopje": "(GMT+01:00) Skopje",
    "Europe/Stockholm": "(GMT+01:00) Stockholm",
    "Europe/Vienna": "(GMT+01:00) Vienna",
    "Europe/Warsaw": "(GMT+01:00) Warsaw",
    "Europe/Zagreb": "(GMT+01:00) Zagreb",
    "Europe/Athens": "(GMT+02:00) Athens",
    "Europe/Bucharest": "(GMT+02:00) Bucharest",
    "Africa/Cairo": "(GMT+02:00) Cairo",
    "Africa/Harare": "(GMT+02:00) Harare",
    "Europe/Helsinki": "(GMT+02:00) Helsinki",
    "Europe/Istanbul": "(GMT+02:00) Istanbul",
    "Asia/Jerusalem": "(GMT+02:00) Jerusalem",
    "Europe/Kiev": "(GMT+02:00) Kyiv",
    "Europe/Minsk": "(GMT+02:00) Minsk",
    "Europe/Riga": "(GMT+02:00) Riga",
    "Europe/Sofia": "(GMT+02:00) Sofia",
    "Europe/Tallinn": "(GMT+02:00) Tallinn",
    "Europe/Vilnius": "(GMT+02:00) Vilnius",
    "Asia/Baghdad": "(GMT+03:00) Baghdad",
    "Asia/Kuwait": "(GMT+03:00) Kuwait",
    "Africa/Dar_es_Salaam": "(GMT+03:00) East Africa Time",
    "Africa/Nairobi": "(GMT+03:00) Nairobi",
    "Asia/Riyadh": "(GMT+03:00) Riyadh",
    "Europe/Moscow": "(GMT+03:00) Moscow",
    "Asia/Tehran": "(GMT+03:30) Tehran",
    "Asia/Baku": "(GMT+04:00) Baku",
    "Europe/Volgograd": "(GMT+04:00) Volgograd",
    "Asia/Muscat": "(GMT+04:00) Muscat",
    "Asia/Tbilisi": "(GMT+04:00) Tbilisi",
    "Asia/Yerevan": "(GMT+04:00) Yerevan",
    "Asia/Kabul": "(GMT+04:30) Kabul",
    "Asia/Karachi": "(GMT+05:00) Karachi",
    "Asia/Tashkent": "(GMT+05:00) Tashkent",
    "Asia/Kolkata": "(GMT+05:30) Kolkata",
    "Asia/Kathmandu": "(GMT+05:45) Kathmandu",
    "Asia/Yekaterinburg": "(GMT+06:00) Ekaterinburg",
    "Asia/Almaty": "(GMT+06:00) Almaty",
    "Asia/Dhaka": "(GMT+06:00) Dhaka",
    "Asia/Novosibirsk": "(GMT+07:00) Novosibirsk",
    "Asia/Bangkok": "(GMT+07:00) Bangkok",
    "Asia/Jakarta": "(GMT+07:00) Jakarta",
    "Asia/Krasnoyarsk": "(GMT+08:00) Krasnoyarsk",
    "Asia/Chongqing": "(GMT+08:00) Chongqing",
    "Asia/Hong_Kong": "(GMT+08:00) Hong Kong",
    "Asia/Kuala_Lumpur": "(GMT+08:00) Kuala Lumpur",
    "Australia/Perth": "(GMT+08:00) Perth",
    "Asia/Singapore": "(GMT+08:00) Singapore",
    "Asia/Taipei": "(GMT+08:00) Taipei",
    "Asia/Ulaanbaatar": "(GMT+08:00) Ulaan Bataar",
    "Asia/Urumqi": "(GMT+08:00) Urumqi",
    "Asia/Irkutsk": "(GMT+09:00) Irkutsk",
    "Asia/Seoul": "(GMT+09:00) Seoul",
    "Asia/Tokyo": "(GMT+09:00) Tokyo",
    "Australia/Adelaide": "(GMT+09:30) Adelaide",
    "Australia/Darwin": "(GMT+09:30) Darwin",
    "Asia/Yakutsk": "(GMT+10:00) Yakutsk",
    "Australia/Brisbane": "(GMT+10:00) Brisbane",
    "Australia/Canberra": "(GMT+10:00) Canberra",
    "Pacific/Guam": "(GMT+10:00) Guam",
    "Australia/Hobart": "(GMT+10:00) Hobart",
    "Australia/Melbourne": "(GMT+10:00) Melbourne",
    "Pacific/Port_Moresby": "(GMT+10:00) Port Moresby",
    "Australia/Sydney": "(GMT+10:00) Sydney",
    "Asia/Vladivostok": "(GMT+11:00) Vladivostok",
    "Asia/Magadan": "(GMT+12:00) Magadan",
    "Pacific/Auckland": "(GMT+12:00) Auckland",
    "Pacific/Fiji": "(GMT+12:00) Fiji"
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatRadioModule,
        MatTooltipModule,
        MatBottomSheetModule,
        MatBadgeModule,
        MatTableModule,
        MatSliderModule,
        MatSlideToggleModule,
        OverlayModule,
        PortalModule,
        DragDropModule,
        ReactiveFormsModule,
        RouterModule,
        DragulaModule.forRoot(),
        MglTimelineModule,
        GoogleMapsModule,
        NgxMatSelectSearchModule,
        QuillModule.forRoot(),
        NgxDocViewerModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatButtonToggleModule,
        ScrollingModule
    ],
    declarations: [
        FileUploadComponent,
        ChangeStatusFormComponent,
        PaginationComponent,
        CommonFormComponent,
        ModalComponent,
        ModalTableComponent,
        SnackbarComponent,
        SchedulerFormComponent,
        SchedulerListComponent,
        MentionsDropdownComponent,
        BarcodeReaderComponent,
        PromptModalComponent,
        ConfirmComponent,
        FieldComponent,
        SpinnerComponent,
        SimpleSpinnerComponent,
        TableComponent,
        WizardComponent,
        FixPipe,
        AutocompleteComponent,
        UserAllowedDirective,
        UserNotAllowedDirective,
        WizardComponentFactoryDirective,
        ObjectKeysPipe,
        SearchPipe,
        ReplacePipe,
        UcFirstPipe,
        TimezonePipe,
        HiddenDirective,
        IfPipe,
        FilterPipe,
        LineBreaksPipe,
        FileUploadComponent,
        CalendarChartComponent,
        GeoChartComponent,
        LineChartComponent,
        DonutChartComponent,
        BubbleChartComponent,
        ComboChartComponent,
        AlertComponent,
        ChangeStatusFormComponent,
        PaginationComponent,
        SignaturePadComponent,
        JoinPipe,
        CommonFormComponent,
        CommonFormFileUploadComponent,
        CommonFormDateComponent,
        CommonFormDateTimeComponent,
        CommonFormDateRangeComponent,
        CommonFormDatePeriodComponent,
        CommonFormSearchComponent,
        CounterComponent,
        CommonFormSelectComponent,
        CommonFormFieldsComponent,
        GmapComponent,
        SidebarComponent,
        CommonFormCheckboxComponent,
        SnackbarComponent,
        FilterComponent,
        CommonTestComponent,
        SortPipe,
        GmapPlacesAutocompleteComponent,
        CommonFormBarcodeComponent,
        Google2faComponent,
        SchedulerFormComponent,
        SchedulerListComponent,
        StrPadPipe,
        CommonFormSwitchComponent,
        Table2Component,
        TableCellComponent,
        ModalTable2Component,
        ItemConfigurationNamesPipe,
        NoScrollDirective,
        ToNumberPipe,
        Url2linkPipe,
        FormBuilderFieldComponent,
        FormBuilderGroupComponent,
        FormBuilderComponent,
        MentionsDirective,
        MentionsDropdownComponent,
        MentionsPipe,
        SafePipe,
        BarcodeReaderComponent,
        HubSelectComponent,
        PromptModalComponent,
        TimelineComponent,
        BarChartComponent,
        PrettyJsonPipe,
        ScannerOnlyDirective,
        BarcodeComponent,
        LivechatComponent,
        CommonFormEditorComponent,
        StaticMapComponent,
        CommonFormEditorComponent,
        ObserveVisibilityDirective,
        ObserveVisibilityDirective,
        ControlDirective,
        LoadConfigComponent,
        ControlDirective,
        ObjectValuesPipe,
        CommonFormErrorComponent,
        MatSelectRemoveAriaOwnsDirective
    ],
    exports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSidenavModule,
        MatSelectModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatRadioModule,
        MatTooltipModule,
        MatSliderModule,
        MatSlideToggleModule,
        OverlayModule,
        PortalModule,
        DragDropModule,
        ReactiveFormsModule,
        RouterModule,
        ConfirmComponent,
        FieldComponent,
        SpinnerComponent,
        SimpleSpinnerComponent,
        TableComponent,
        WizardComponent,
        FixPipe,
        DragulaModule,
        AutocompleteComponent,
        UserAllowedDirective,
        UserNotAllowedDirective,
        WizardComponentFactoryDirective,
        ObjectKeysPipe,
        SearchPipe,
        ReplacePipe,
        UcFirstPipe,
        TimezonePipe,
        HiddenDirective,
        IfPipe,
        FilterPipe,
        LineBreaksPipe,
        FileUploadComponent,
        CalendarChartComponent,
        GeoChartComponent,
        LineChartComponent,
        DonutChartComponent,
        BubbleChartComponent,
        ComboChartComponent,
        AlertComponent,
        ChangeStatusFormComponent,
        MglTimelineModule,
        PaginationComponent,
        MatBadgeModule,
        SignaturePadComponent,
        MatTableModule,
        JoinPipe,
        CounterComponent,
        CommonFormComponent,
        ModalComponent,
        ModalTableComponent,
        GoogleMapsModule,
        CommonFormFieldsComponent,
        NgxMatSelectSearchModule,
        GmapComponent,
        SidebarComponent,
        QuillModule,
        BarcodeComponent,
        FilterComponent,
        CommonTestComponent,
        SortPipe,
        GmapPlacesAutocompleteComponent,
        CommonFormBarcodeComponent,
        Google2faComponent,
        SchedulerFormComponent,
        SchedulerListComponent,
        StrPadPipe,
        CommonFormSwitchComponent,
        Table2Component,
        TableCellComponent,
        ModalTable2Component,
        ItemConfigurationNamesPipe,
        CommonFormSelectComponent,
        NoScrollDirective,
        ToNumberPipe,
        Url2linkPipe,
        FormBuilderComponent,
        MentionsDirective,
        MentionsPipe,
        SafePipe,
        BarcodeReaderComponent,
        HubSelectComponent,
        CommonFormDateComponent,
        CommonFormDateTimeComponent,
        CommonFormDatePeriodComponent,
        PromptModalComponent,
        TimelineComponent,
        CommonFormCheckboxComponent,
        BarChartComponent,
        PrettyJsonPipe,
        CommonFormSearchComponent,
        NgxDocViewerModule,
        ScannerOnlyDirective,
        LivechatComponent,
        HttpClientModule,
        HttpClientJsonpModule,
        CommonFormEditorComponent,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        ScannerOnlyDirective,
        CommonFormDateRangeComponent,
        MatButtonToggleModule,
        StaticMapComponent,
        MatButtonToggleModule,
        ObserveVisibilityDirective,
        ControlDirective,
        LoadConfigComponent,
        ObjectValuesPipe,
        CommonFormErrorComponent,
        MatSelectRemoveAriaOwnsDirective
    ],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    ],
})
export class CommonModule {

    public static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CommonModule,
            providers: [
                ApiService,
                Api3Service,
                HelpersService,
                SoundsService,
                GuardService,
                LoginService,
                StorageService,
                ToastService,
                UserService,
                GoogleChartsService,
                AppLockService,
                PusherService,
                NotificationService,
                SpinnerService,
                OrderService,
                PartMasterService,
                AddressService,
                ContractService,
                ServiceLevelService,
                PartnerService,
                ThpeeplService,
                WarehouseService,
                CourierService,
                TransactionService,
                ImageUploadService,
                ReportService,
                CourierService,
                RequestService,
                CourierService,
                ShipmentService,
                PaginationService,
                HubService,
                PickupRequestService,
                AttachmentService,
                MilestonesService,
                GoogleMapsApiService,
                AppStateService,
                MediaScreenService,
                AppStateService,
                BatchService,
                TeamService,
                AmplitudeService
            ]
        };
    }

}

export {GuardService};
