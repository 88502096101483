import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-form-checkbox",
    template: `
        <div>
            <mat-checkbox [formControl]="formControl" [required]="required">
                {{label}}
            </mat-checkbox>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormCheckboxComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public label: string;

    @Input()
    public required: boolean = false;

    @Input()
    public value: boolean = false;

    @Output()
    public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formControl: FormControl =
        new FormControl(this.value, this.required ? [Validators.required] : []);

    public ngOnInit(): void {
        this.formControl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((checked: boolean): void => {
                this.valueChange.emit(checked);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.value) {
            this.formControl.setValue(
                typeof changes.value.currentValue === "boolean"
                ? changes.value.currentValue
                : false
            );
        }
    }
}
