<h1 class="container-heading">Add status</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor='let status of statuses' [value]="status.toLowerCase()">
                            {{ status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Message</mat-label>
                    <input matInput formControlName="messages" required>
                </mat-form-field>

                <div class="full">
                    <common-autocomplete [options]="countries" placeholder="Location" [allowFreeInput]="false"
                                         class="full" (optionSelected)="onCountrySelected($event)"
                                         [errorText]="'Select country'" [required]="true"
                                         [image]="{path:'assets/images/flags',ext:'svg'}"
                                         [value]="formGroup ? formGroup.value.location : null"></common-autocomplete>

                </div>


                <div class="half">
                    <common-form-date-time
                            label="Pickup date"
                            [required]="true"
                            [value]="formGroup.value.pickup_date"
                            (valueChange)="formGroup.get('pickup_date').setValue($event)"
                    ></common-form-date-time>
                </div>

                <div class="half">
                    <common-form-select
                            label="Pickup date timezone"
                            [options]="timezones"
                            [search]="true"
                            [required]="true"
                            [value]="formGroup.value.pickup_date_tz"
                            (valueChange)="formGroup.get('pickup_date_tz').setValue($event)"
                    ></common-form-select>
                </div>


                <div class="full">
                    <common-form-date
                            label="Expected delivery"
                            [value]="formGroup.value.expected_delivery"
                            [required]="true"
                            (valueChangeMoment)="formGroup.get('expected_delivery').setValue($event)"
                    ></common-form-date>
                </div>

                <mat-form-field class="full" *ngIf="formGroup.get('pod')">
                    <mat-label>Signed by</mat-label>
                    <input matInput formControlName="pod" required>
                </mat-form-field>

                <div class="full" *ngIf="formGroup.get('delivered_at')">
                    <common-form-date
                            label="Delivered at"
                            [value]="formGroup.value.delivered_at"
                            [required]="true"
                            (valueChangeMoment)="formGroup.get('delivered_at').setValue($event)"
                    ></common-form-date>
                </div>

                <div *ngIf="modal.params.file_upload" class="full margin-bottom-20">
                    <div *ngIf="formGroup.value.attachment">
                        <b>Attachment:</b> {{ formGroup.value.attachment?.name }}
                    </div>
                    <common-file-upload
                            *ngIf="!formGroup.value.attachment"
                            (result)="onResult($event)"
                            [accept]="['pdf', 'jpeg', 'png']"
                            [url]="['remark', 'order', modal.params.data.order.ref, 'remark_attachment']"
                    ></common-file-upload>
                </div>
            </div>

            <div class="actions">
                <button [disabled]="!this.formGroup || !this.formGroup.valid"
                        mat-raised-button color="primary" type="submit" class="main">
                    Save
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>
