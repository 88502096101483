import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../common/components/table2";
import {UserService} from "../../../../../../../common/services/user.service";
import {AmplitudeService} from "../../../../../../../common/services/amplitude.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {CustomFieldsEditModalComponent}
    from "../../../../../common/components/custom-fields/edit-modal/edit-modal.component";

@Component({
    selector: "section-partner-form-step4",
    templateUrl: "step4.component.html",
    styleUrls: [
        "step4.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerFormStep4Component implements OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private slug: string;

    private state: Base.IState;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @Output()
    public authFail: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("logTable", {static: false})
    public logTableRef: Table2Component;

    public newForm: FormGroup = new FormGroup({
        is_active: new FormControl(true),
        threepl: new FormControl(null, [Validators.required]),
        warehouse_slug: new FormControl(null, [Validators.required]),
        sub_inventory: new FormControl(null, [Validators.required]),
        customers_inventory_name: new FormControl(null, [Validators.required]),
        customers_sub_inventory: new FormControl(null, [Validators.required]),
        gb: new FormControl(null, [Validators.required]),
        hub_group_id: new FormControl(null),
        properties: new FormGroup({
            special_confirmation: new FormControl(false)
        })
    });

    public itemForm: FormGroup = new FormGroup({
        is_active: new FormControl(true),
        threepl: new FormControl(null, [Validators.required]),
        warehouse_slug: new FormControl(null, [Validators.required]),
        sub_inventory: new FormControl(null, [Validators.required]),
        customers_inventory_name: new FormControl(null, [Validators.required]),
        customers_sub_inventory: new FormControl(null, [Validators.required]),
        gb: new FormControl(null, [Validators.required]),
        id: new FormControl(null, [Validators.required]),
        hub_group_id: new FormControl(null),
        properties: new FormControl({})
    });

    public threepls: { value: string, name: string }[] = [];

    public warehouses: { new: { value: string, name: string }[], item: { value: string, name: string }[] } =
        {new: [], item: []};

    public subWarehouses: { new: { value: string, name: string }[], item: { value: string, name: string }[] } =
        {new: [], item: []};


    public hubs: IPagination<Warehouse.IHub>;

    public groups: ISelectOption[] = [];

    public showSpinner: boolean = false;

    public filters: FormGroup = new FormGroup({
        hub_group_id: new FormControl(null),
        search: new FormControl(null)
    });

    public openedItem: number;

    public logsTableSettings: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private modalService: ModalService,
    ) {
    }


    /**
     * Get threepls list
     * @returns {Promise<any>}
     */
    private async getThreePls(): Promise<any> {
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["threepl"]);
        if (this.checkAuth(response) && response.data) {
            this.threepls = response.data.map((item: any): any => {
                return {name: item.display_name, value: item.slug};
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getGroups(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/hub-groups`, {
            data_structure: "select",
            partner_id: this.state.params.id // for admin partner edit wizard
        });
        this.spinnerService.hide();

        if (data) {
            this.groups = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Check response from server
     * @param {Api.IResponse} response
     * @returns {boolean}
     */
    private checkAuth(response: Api.IResponse): boolean {
        if (response.code === 401) {
            this.authFail.emit(true);
            return false;
        }
        return true;
    }

    private getLogsUrl(): Table.ITableApi {
        return {
            url: ["hub", "logs"],
            query: {
                partner_slug: this.slug
            }
        };
    }

    private async prepareLogsTable(): Promise<any> {

        this.logsTableSettings = {
            actions: [],
            columns: [
                {
                    data: "name",
                    title: "Log"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "properties.previous_state",
                    title: "From"
                },
                {
                    data: "properties.current_state",
                    title: "To"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "By"
                },
                {
                    data: "created_at",
                    title: "At"
                },

            ],
            api: this.getLogsUrl(),
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }

    public async editProperties(): Promise<any> {

        const properties = this.itemForm.get("properties").value || {};
        const data = [];
        Object.keys(properties).forEach((key: string, index: number): void => {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            data[index] = {
                key,
                value: properties[key]
            };
        });

        const res: Modal.IResponse = await this.modalService.open(CustomFieldsEditModalComponent, {
            custom_fields: Object.values(data),
            window_title: "Custom properties"
        });

        if (res && res.value) {
            this.toastService.show("Properties set. Save hub to confirm changes", "success");
            this.itemForm.get("properties").setValue(res.value);

            this.submitUpdate();
        }
    }


    /**
     * Get items to display
     * @returns {Promise<any>}
     */
    public async getItems(page: number = 1, per_page: number = null, filter: any = null): Promise<any> {
        this.showSpinner = true;
        this.hubs = null;
        this.changeDetectorRef.markForCheck();
        this.apiService.setPartner(this.slug);
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["hub"], {}, {
                data_structure: "paginated",
                page,
                per_page,
                hub_group_id: filter ? filter.hub_group_id : null,
                search_by: filter ? filter.search : null,
                no_sort: true,
                search_in: ["customers_inventory_name", "warehouse.name"],
                with_hidden: true
            });
        this.showSpinner = false;
        if (this.checkAuth(response) && response.data) {
            this.hubs = response.data;
            if (this.hubs.data.length > 0) {
                this.result.next(true);
            }
            this.changeDetectorRef.markForCheck();
        }

    }

    public prepareItemForm(item: any): void {
        this.itemForm.patchValue({
            id: item.id,
            is_active: item.is_active,
            threepl: item.warehouse ? item.warehouse.threepl.slug : null,
            warehouse_slug: item.warehouse_slug,
            sub_inventory: item.sub_inventory,
            customers_inventory_name: item.customers_inventory_name,
            customers_sub_inventory: item.customers_sub_inventory,
            gb: item.gb,
            hub_group_id: item.hub_group_id,
            properties: item.properties
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param slug
     */
    public async init(slug: string): Promise<any> {
        this.slug = slug;
        this.state = AppStateService.getState();
        this.getThreePls();
        this.getItems();
        this.prepareLogsTable();
        this.getGroups();

        this.filters.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getItems(1, 10, value);
            });

        this.itemForm.get("threepl").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: string): void => {
                if (value) {
                    this.getWarehouses("item", value);
                }
            });

        this.itemForm.get("warehouse_slug").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: string): void => {
                if (value) {
                    this.getSubWarehouses("item", value);
                }
            });

        this.newForm.get("threepl").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: string): void => {
                if (value) {
                    this.getWarehouses("new", value);
                }
            });

        this.newForm.get("warehouse_slug").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: string): void => {
                if (value) {
                    this.getSubWarehouses("new", value);
                }
            });


    }

    /**
     * Get warehouses list for item ot new form
     * @returns {Promise<any>}
     * @param scope
     * @param threepl
     */
    public async getWarehouses(scope: "new" | "item", threepl: string): Promise<any> {
        this.spinnerService.show();

        this.apiService.setPartner(this.slug);
        this.apiService.setHeaders({threepl});

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse"]);

        this.spinnerService.hide();

        if (this.checkAuth(response) && response.data) {
            this.warehouses[scope] = response.data.map((item: any): any => {
                return {name: `${item.name} (${item.type})`, value: item.slug};
            });

        } else {
            this.warehouses[scope] = [];
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get subwarehouses list for item or new form
     * @param scope
     * @param warehouse
     */
    public async getSubWarehouses(scope: "new" | "item", warehouse: string): Promise<any> {
        this.spinnerService.show();

        this.apiService.setPartner(this.slug);
        this.apiService.setHeaders({warehouse});

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "sub"], {}, {all: true});

        this.spinnerService.hide();

        if (this.checkAuth(response) && response.data) {
            this.subWarehouses[scope] = response.data.map((item: any): any => {
                return {name: item.sub_inventory, value: item.sub_inventory};
            });
        } else {
            this.subWarehouses[scope] = [];
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit new item
     * @returns {Promise<any>}
     */
    public async submitNew(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["hub"],
            [{...this.newForm.value}]);
        this.toastService.show(response.message, response.type as string);

        this.spinnerService.hide();

        if (response.data) {
            this.getItems();
            this.newForm.reset();
            this.result.next(true);
            this.logTableRef.reload(this.getLogsUrl());
        }
        AmplitudeService.eventClick("Submit hubs");
    }

    /**
     * Update existing item
     * @returns {Promise<any>}
     */
    public async submitUpdate(): Promise<any> {
        this.spinnerService.show();

        this.apiService.setPartner(this.slug);
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["hub", "" + this.itemForm.value.id], this.itemForm.value);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.getItems();
            this.logTableRef.reload(this.getLogsUrl());
        }
        AmplitudeService.eventClick("Update hubs");
    }

    public amplitudeClick(message: string, properties: any = {}): void {
        AmplitudeService.eventClick(message, properties);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
