import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../..";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import {BoxStickerComponent} from "../../../../boxes";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {PackageFormComponent} from "../../../../package";
import {UserService} from "../../../../../../../../common/services/user.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";

@Component({
    selector: "section-warehouse-procedures-wizard-outbound-scan-boxes",
    templateUrl: "scan.component.html",
    styleUrls: [
        "scan.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardOutboundScanBoxesComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private warehouseOrderId: number;

    private prevKey: string = null;

    public shipment: Warehouse.IShipment;

    public showFreeBoxes: boolean = false;

    public totalBoxesInOrder: number = 0;

    @ViewChild("locationCountInput", {static: false})
    public locationCountInput: ElementRef;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    public scannerKey: string;

    public partner: User.IPartner;

    public searchInput: FormControl = new FormControl(null);

    public searchInputLabel: string = "Box";

    public selectedPackage: FormControl = new FormControl(null);

    public packages: any[] = [];

    public locations: IPagination<any>;

    public serialTemp: string = null;
    public boxTemp: string = null;
    public locationTemp: string = null;

    public orderRef: string;

    public scannerOnly: boolean = false;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get package list
     */
    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_package", "shipment", "" + this.shipment.id]);
        if (data) {
            this.packages = data;
            if (this.packages.length === 0) {
                this.newPackadge();
                this.soundsService.textToSpeech("Please create package");
            } else {
                this.selectedPackage.setValue(this.packages[0].id);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Get shipment
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id]);
        if (data) {
            this.shipment = data;
            this.scannerOnly = this.shipment && this.shipment.partner
                ? this.shipment.partner.properties.disable_manual_scanning
                : false;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get boxes count in order
     */
    private async getBoxCountInOrder(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "warehouse_order", "" + this.warehouseOrderId], {}, {
                data_structure: "count"
            });
        if (data) {
            this.totalBoxesInOrder = data[0];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async getBoxCountLocation(location: any, index: number): Promise<any> {
        this.locations.data[index].c = location.boxes.length;
    }

    /**
     * Get locations & boxes
     */
    public async getLocations(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "shipment", "" + this.shipment.id], {}, {
                type: "outbound",
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            this.locations = data;
            this.locations.data.forEach((location: any, index: number): void => {
                this.getBoxCountLocation(location, index);
            });
            this.changeDetectorRef.markForCheck();
            this.getBoxCountInOrder();
        }
        this.spinnerService.hide();
    }

    public async exportPackList(type: string): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "shipment", "" + this.shipment.id], {}, {
                export: type
            });
        if (data && data.length) {
            window.open(data[0], "_blank");
        }

        if (message) {
            this.toastService.show(message, "success");
        }
        this.spinnerService.hide();
    }

    /**
     * Create new package
     */
    public async newPackadge(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent,
            {
                shipmentId: this.shipment.id,
                warehouse: this.shipment.warehouse
            });

        if (response) {
            this.getPackages();
        }
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    /**
     * Find box
     */
    public async find(): Promise<any> {
        this.spinnerService.show();
        const {data, error, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["box", "warehouse_order", "" + this.warehouseOrderId, "scan"], {
                ref: this.searchInput.value,
                package_id: this.selectedPackage.value,
                shipment_id: this.shipment.id,
                serial: this.serialTemp,
                box: this.boxTemp,
                location: this.locationTemp
            }, {type: "outbound"});

        this.soundsService.textToSpeech(message);
        this.searchInput.setValue(null);
        this.spinnerService.hide();

        if (error) {
            this.serialTemp = null;
            this.boxTemp = null;
            this.locationTemp = null;
        } else if (data.location) {
            this.locationTemp = data.location.location;
            if (data.box) {
                this.boxTemp = data.box.ref;
            }
        } else if (data.serial) {
            this.serialTemp = data.serial;
        } else if (data.box) {
            this.serialTemp = null;
            this.boxTemp = null;
            this.locationTemp = null;
            this.getLocations();
        }

        setTimeout((): void => {
            this.searchInputRef.nativeElement.focus();
        }, 1000);
    }

    /**
     * Show items in the box
     * @param box
     */
    public async openBox(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxItemsListComponent,
            {
                box,
                modalWidth: 1000,
                itemsType: this.showFreeBoxes ? "allocation" : "warehouse-transactions"
            });
    }

    /**
     * Print box sticker
     * @param box
     */
    public async printSticker(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxStickerComponent, {
            box,
            orderRef: this.orderRef
        });
    }


    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.scannerKey = data.scannerKey;
        this.shipment = data.shipment;
        this.warehouseOrderId = data.warehouseOrderId;
        this.orderRef = data.orderRef;

        this.changeDetectorRef.markForCheck();
        this.getShipment();
        this.getPackages();
        this.getLocations();

        if (this.selectedPackage.value) {
            this.searchInputRef.nativeElement.focus();
        }

        this.result.emit({
            action: "result",
            value: true
        });
    }


    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
