import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {UntypedFormArray, UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {HubService} from "../../../../../../common/services/hub.service";

interface IContact {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    fax: string;
    customer: {
        name: string;
        vat: string;
    };
}

interface IBranch {
    display_name: string;
    full_name: string;
    address: {
        address: string;
        address2: string;
        city: string;
        zip: string;
        state: string;
        country: string;
        address_type: string;
        contact: IContact[]
    };
    customer: {
        name: string;
        vat: string;
    };
    contacts: IContact[];
    countries: any[];
    hubs: Warehouse.IHub[];
    custom_fields: any;
    eor: string;
    ior: string;
}

@Component({
    selector: "section-branch-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BranchFormComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public readonly state: Base.IState;
    public branches: any;
    public isEdit: boolean;
    public countries: any[];
    public hubs: Warehouse.IHub[];
    public filtered_hubs: Warehouse.IHub[] = [];
    public countries_prefiltered: any[];
    public countries_filtered: any[];
    public branch_countries: any[];
    public branch_hubs: number[] = [];
    public branch_iso: string;
    public countriesSearchControl: FormControl = new FormControl("");
    public hubsSearchControl: FormControl = new FormControl("");
    public contacts: FormGroup[] = [];

    public branch: FormGroup = this.fb.group({
        display_name: ["", [Validators.required]],
        full_name: ["", [Validators.required]],
        address: this.fb.group({
            address: ["", [Validators.required]],
            address2: [""],
            city: ["", [Validators.required]],
            zip: ["", [Validators.required]],
            state: [""],
            country: ["", [Validators.required]]
        }),
        term: [null],
        incoterms: [null],
        custom_incoterm: [null],
        notes: this.fb.array([]),
        custom_fields: this.fb.array([]),
        include_to_invoice: this.fb.group({
            display_name: [true],
            address: [true]
        }),
        eor_name: ["EOR", [Validators.required]],
        ior_name: ["IOR", [Validators.required]],
    });

    public contactForm: FormGroup = this.fb.group({
        first_name: ["", [Validators.required]],
        last_name: ["", [Validators.required]],
        email: [""],
        phone: [""],
        fax: [""]
    });

    public constructor(
        private apiService: ApiService,
        private fb: UntypedFormBuilder,
        private toastService: ToastService,
        private router: Router,
        private helpers: HelpersService,
        private changeDetectorRef: ChangeDetectorRef,
        private hubService: HubService,
        private spinnerService: SpinnerService
    ) {
    }

    private contact(opts: {
        first_name: string,
        last_name: string,
        email: string,
        phone: string,
        fax: string
    }): FormGroup {
        return (this.fb.group({
            first_name: [opts.first_name, [Validators.required]],
            last_name: [opts.last_name, [Validators.required]],
            email: [opts.email],
            phone: [opts.phone],
            fax: [opts.fax]
        }));
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
        this.countries.sort((a: { value: string, name: string }, b: { value: string, name: string }): number => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        this.branch.get("address.country").setValue(this.branch_iso);
        this.countries_prefiltered = this.countries.filter((item: any): boolean => {
            for (const country of this.branch_countries) {
                if (item.value === country.iso) {
                    return false;
                }
            }
            return true;
        });
        this.countries_filtered = this.countries_prefiltered;
    }

    private async getHubs(): Promise<any> {
        const {data}: Api.IResponse = await this.hubService.getHubs();
        if (data) {
            this.hubs = data;
            this.filtered_hubs = this.hubs;
            this.changeDetectorRef.markForCheck();
        }
    }

    private prepareBody(): any {
        const data: any = {...this.branch.value};
        if (this.contacts.length > 0) {
            data.contacts = [];
        }
        for (const item of this.contacts) {
            data.contacts.push(item.value);
        }
        if (this.branch_countries.length > 0) {
            data.countries = [];
        }
        for (const item of this.branch_countries) {
            data.countries.push(item.iso);
        }

        data.hubs = this.branch_hubs.map((hub: any) => {
            return hub.id;
        });

        data.custom_fields = {};
        for (const group of (this.branch.get("custom_fields") as UntypedFormArray).controls) {
            if (group.valid) {
                data.custom_fields[group.value.name] = group.value.value;
                if (!data.include_to_invoice.custom_fields) {
                    data.include_to_invoice.custom_fields = {};
                }
                data.include_to_invoice.custom_fields[group.value.name] = group.value.include_to_invoice;
            }
        }

        data.notes = [];
        for (const group of (this.branch.get("notes") as UntypedFormArray).controls) {
            if (group.valid) {
                data.notes.push(group.value.note);
            }
        }

        if (data.incoterms === "custom") {
            data.incoterms = data.custom_incoterm;
        }

        return data;
    }

    public onCountrySelected($event: any): void {
        this.branch.get("address.country").setValue($event.value);
    }

    public async updateBranch(): Promise<any> {
        this.spinnerService.show();
        let response: Api.IResponse;
        this.apiService.setPartner(this.state.section.split("/")[1]);

        if (this.isEdit) {
            response = await this.apiService.request(Api.EMethod.Put,
                ["partner", "branches", this.state.params.id], this.prepareBody());
        } else {
            response = await this.apiService.request(Api.EMethod.Post,
                ["partner", "branches"], this.prepareBody());
        }
        this.spinnerService.hide();

        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            this.router.navigate([this.state.section, "branches"]);
        }
    }

    public async deleteBranch(): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to delete branch?`)) {
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["partner", "branches", this.state.params.id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.router.navigate([this.state.section, "branches"]);
            }
        }
    }

    public async newContact(model: any, isValid: boolean): Promise<any> {
        this.contacts.push(this.contact(model));
    }

    public async deleteContact(index: number): Promise<any> {
        this.contacts.splice(index, 1);
    }

    public async getdata(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(
            Api.EMethod.Get, ["partner", "branches", this.state.params.id]
        );

        if (!data.notes) {
            data.notes = [];
        }

        if (data.custom_fields) {
            for (const key of Object.keys(data.custom_fields)) {
                this.addCustomField({
                    name: key,
                    value: data.custom_fields[key],
                    include_to_invoice:
                        (data.include_to_invoice.custom_fields && data.include_to_invoice.custom_fields[key] != null)
                            ? data.include_to_invoice.custom_fields[key]
                            : true
                });
            }

            data.custom_fields = [];
        }

        this.branch.patchValue(data);

        this.branches = data;
        this.branch_iso = data.address.country_iso_2;
        for (const item of data.contacts) {
            this.contacts.push(this.contact(item));
        }
        for (const item of data.countries) {
            this.branch_countries.push({iso: item.country_iso_2, name: item.country_name});
        }

        for (const item of data.hubs) {
            this.branch_hubs.push(item);
        }

        const hubsIds = this.branch_hubs.map((item: any) => {
            return item.id;
        });
        this.filtered_hubs = this.hubs.filter((item: any): boolean => {
            return !hubsIds.includes(item.id);
        });


        if (data.notes) {
            for (const value of data.notes) {
                this.addNoteField(value);
            }
        }

        if (data.incoterms && !["DDP", "DAP", "EXW", "CIF"].includes(data.incoterms)) {
            this.branch.get("incoterms").setValue("custom");
            this.branch.get("custom_incoterm").setValue(data.incoterms);
        }

    }

    public countryFilter(el: any, i: any, array: any): boolean {
        return (el.name.toLowerCase().indexOf(("" + this).toLowerCase()) !== -1);
    }

    public hubFilter(el: any, i: any, array: any): boolean {
        return (el.customers_inventory_full_name.toLowerCase().indexOf(("" + this).toLowerCase()) !== -1);
    }

    public addCountry(iso: string, name: string): void {
        this.branch_countries.push({iso: iso, name: name});
        this.countries_prefiltered = this.countries_prefiltered.filter((item: any): boolean => {
            return item.value !== iso;
        });
        this.countries_filtered = this.countries_prefiltered.filter(
            this.countryFilter.bind(this.countriesSearchControl.value)
        );
    }

    public removeCountry(iso: string, name: string): void {
        this.countries_prefiltered.push({value: iso, name: name});
        this.countries_prefiltered
            .sort((a: { value: string, name: string }, b: { value: string, name: string }): number => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
        this.branch_countries = this.branch_countries.filter((item: any): boolean => {
            return item.iso.toLowerCase() !== iso.toLowerCase();
        });
        this.countries_filtered = this.countries_prefiltered.filter(
            this.countryFilter.bind(this.countriesSearchControl.value)
        );
    }

    public addHub(hub: any): void {
        this.branch_hubs.push(hub);
        const ids = this.branch_hubs.map((item: any) => {
            return item.id;
        });
        this.filtered_hubs = this.hubs.filter((item: any): boolean => {
            return item.id !== hub.id && !ids.includes(item.id);
        });
        this.filtered_hubs = this.filtered_hubs.filter(
            this.hubFilter.bind(this.hubsSearchControl.value)
        );
        this.changeDetectorRef.markForCheck();
    }

    public removeHub(id: number): void {
        this.branch_hubs = this.branch_hubs.filter((item: any): boolean => {
            return item.id !== id;
        });
        const ids = this.branch_hubs.map((item: any) => {
            return item.id;
        });
        this.filtered_hubs = this.hubs.filter((item: any): boolean => {
            return !ids.includes(item.id);
        });
        this.filtered_hubs = this.filtered_hubs.filter(
            this.hubFilter.bind(this.hubsSearchControl.value)
        );
        this.changeDetectorRef.markForCheck();
    }

    public addCustomField(field: { name: string, value: string, include_to_invoice } = {
        name: null,
        value: null,
        include_to_invoice: true
    }): void {

        (this.branch.get("custom_fields") as UntypedFormArray).push(new FormGroup({
            name: new FormControl(field.name, [Validators.required]),
            value: new FormControl(field.value, [Validators.required]),
            include_to_invoice: new FormControl(field.include_to_invoice)
        }));
        this.changeDetectorRef.markForCheck();
    }

    public removeCustomField(index: number): void {
        (this.branch.get("custom_fields") as UntypedFormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }

    public getArrayControls(name: string): UntypedFormArray {
        return (this.branch.get(name) as UntypedFormArray);
    }

    public addNoteField(value: string = null): void {
        (this.branch.get("notes") as UntypedFormArray).push(
            new FormGroup({
                note: new FormControl(value, [Validators.required])
            })
        );
        this.changeDetectorRef.markForCheck();
    }

    public removeNoteField(index: number): void {
        (this.branch.get("notes") as UntypedFormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }


    public async ngOnInit(): Promise<void> {
        await this.getHubs();
        this.branch_countries = [];
        if (this.state.action === "edit") {
            this.isEdit = true;
            this.getdata().then((): void => {
                this.prepareCountriesList();
            });
        } else {
            this.prepareCountriesList();
        }
        this.countriesSearchControl.valueChanges
            .subscribe((val: string): void => {
                this.countries_filtered = this.countries_prefiltered.filter(this.countryFilter.bind(val));
            });
        this.hubsSearchControl.valueChanges
            .subscribe((val: string): void => {
                const ids = this.branch_hubs.map((item: any) => {
                    return item.id;
                });
                this.filtered_hubs = this.hubs.filter((item: any): boolean => {
                    return !ids.includes(item.id);
                });
                this.filtered_hubs = this.filtered_hubs.filter(this.hubFilter.bind(this.hubsSearchControl.value));
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "branches",
            actions: {
                "add": ["add_partner_branches"],
                "edit": ["edit_partner_branches"]
            }
        };
    }


}
