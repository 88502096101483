import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {takeUntil} from "rxjs/operators";
import {UserService} from "src/modules/common/services/user.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Table2Component } from "src/modules/common/components/table2";
import { Table } from "src/modules/common/interfaces/table.interface";
import { AppStateService } from "src/modules/common/services/app-state.service";

@Component({
    selector: "section-courier-service-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CourierServiceFormComponent implements Base.IComponent, OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;

    @ViewChild("logTable", {static: false})
    public logTableRef: Table2Component;

    public logsTableSettings: Table.ISettings;

    public formGroup: FormGroup = new FormGroup({
        "service_name": new FormControl(null, [Validators.required]),
        "description": new FormControl(null),
        "other_names": new FormControl([]),
        "cutt_off": new FormControl(null, [Validators.required]),
        "service_type": new FormControl("OTHERS", [Validators.required]),
        "hours_to_sla": new FormControl(null),
        "arrives_by": new FormControl(null),
        "days": new FormControl(null),
        "trackable": new FormControl(false),
        "couriers_slug": new FormControl(null),
        "aftership_slug": new FormControl(null),
        "volumetric_weight_index": new FormControl(6000, [Validators.required]),
        "is_urgent": new FormControl(false),
        "is_active": new FormControl(true),
        "is_traced_by_warehouse": new FormControl(false),
        "fuel_surcharge": new FormControl(0, [Validators.min(0)]),
        "delivery_type": new FormControl("int+dom", [Validators.required])
    });

    public aftership_slugs: any[];

    public cutOffTimeSlots: any[];

    public arriveByTimeSlots: any[];

    public chips: any[] = [];

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    private async prepareForm(): Promise<any> {
        this.formGroup.get("service_type").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: string): void => {
                if (val === "DAYS") {
                    this.formGroup.get("hours_to_sla").clearValidators();
                    this.formGroup.get("days").setValidators([Validators.required]);
                    this.formGroup.get("arrives_by").setValidators([Validators.required]);
                } else if (val === "HOURS") {
                    this.formGroup.get("hours_to_sla").setValidators([Validators.required]);
                    this.formGroup.get("days").clearValidators();
                    this.formGroup.get("arrives_by").clearValidators();
                } else {
                    this.formGroup.get("hours_to_sla").clearValidators();
                    this.formGroup.get("days").clearValidators();
                    this.formGroup.get("arrives_by").clearValidators();
                }
                this.formGroup.get("hours_to_sla").updateValueAndValidity();
                this.formGroup.get("days").updateValueAndValidity();
                this.formGroup.get("arrives_by").updateValueAndValidity();
                this.changeDetectorRef.markForCheck();
            });

        if (this.state.action === "edit") {
            this.spinnerService.show();
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                ["courier_service", this.state.params.id]);

            if (data) {
                this.formGroup.patchValue(data);
                this.formGroup.get("other_names").setValue(data.conversions?.map(item => {
                    this.chips.push(item.name);
                    return item.name;
                }) ?? []) ;
            }
            this.spinnerService.hide();
        } else {
            this.formGroup.get("couriers_slug").setValue(this.state.params.courier);
            this.formGroup.get("aftership_slug").setValue(this.state.params.courier);
        }

        if (!this.userService.validatePermissions("manage_courier_service_active_status")) {
            this.formGroup.get("is_active").disable();
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareAftershipSlugsList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier_service", "aftership"], null, {
                data_structure: "select"
            });
        if (Array.isArray(data)) {
            this.aftership_slugs = data;
            const aftership_slug = this.formGroup.get("aftership_slug").value;
            if (aftership_slug && this.aftership_slugs.findIndex((value) => {
                return value.value === aftership_slug;
            }) < 0) {
                this.aftership_slugs.push({
                    name: aftership_slug,
                    value: aftership_slug
                });
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    private getLogsUrl(): Table.ITableApi {
        return {
            url: ["courier_service", this.state.params.id, "logs"]
        };
    }

    private async prepareLogsTable(): Promise<any> {

        this.logsTableSettings = {
            table_id: "admin_courier_service_logs",
            actions: [],
            columns: [
                {
                    data: "name",
                    title: "Log"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "properties.previous_state",
                    title: "From"
                },
                {
                    data: "properties.current_state",
                    title: "To"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "By"
                },
                {
                    data: "created_at",
                    title: "At"
                },

            ],
            api: this.getLogsUrl(),
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }

    /**
     * Add chip
     * @param event
     */
    public addChip(event: MatChipInputEvent): void {
        const value: string = event.value.trim();
        if (event.value) {
            event.value = "";
            this.changeDetectorRef.markForCheck();
        }
        if (value) {
            if (this.formGroup.get("other_names").value === null) {
                this.formGroup.get("other_names").setValue([]);
            }

            const values: string[] = value.split(/[;|,]/);
            for (const val of values) {
                if (val && !this.formGroup.get("other_names").value.includes(val)) {
                    this.chips.push(val);
                }
            }
            this.formGroup.get("other_names").setValue(this.chips);
        }
        event.chipInput!.clear();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove chip
     * @param chip
     */
    public removeChip(chip: string): void {
        const index: number = this.chips.indexOf(chip);
        if (index >= 0) {
            this.chips.splice(index, 1);
            this.formGroup.get("other_names").setValue(this.chips);
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle form submit event
     * @returns {Promise<any>}
     */
    public async formSubmit(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(
            this.state.action === "edit" ? Api.EMethod.Put : Api.EMethod.Post,
            this.state.action === "edit" ? ["courier_service", this.state.params.id] : ["courier_service"],
            this.formGroup.value);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.changeDetectorRef.markForCheck();
            if (response.type as string === "success") {
                this.returnToList();
            }
        }
        this.spinnerService.hide();
    }

    public returnToList(): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "filter",
            "courier",
            this.state.params.courier
        ]);
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onAftershipSlugSelected($event: any): void {
        this.formGroup.get("aftership_slug").setValue($event.value);
    }


    public async ngOnInit(): Promise<any> {
        this.arriveByTimeSlots = HelpersService.getTimeSlots(2, true);
        this.cutOffTimeSlots = HelpersService.getTimeSlots(2, true);
        this.cutOffTimeSlots.unshift({value: "wh", name: "Warehouse cutt off"});


        await this.prepareForm();
        this.prepareAftershipSlugsList();
        if (this.state.action == "edit") {
            this.prepareLogsTable();
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "courier-services",
            actions: {
                "add": ["add_couriers_services"],
                "edit": ["edit_couriers_services"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
