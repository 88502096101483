import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import {StorageService} from "./storage.service";
import {AbstractControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {User} from "../interfaces/user.interface";
import * as moment from "moment";
import {Moment} from "moment";
import {DATE_FORMATS} from "../common.module";
import {Warehouse} from "../interfaces/warehouse.interface";
import Diff = moment.unitOfTime.Diff;
import {count} from "rxjs";

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

@Injectable()
export class HelpersService {

    public constructor(
        private apiService: ApiService,
        private storageService: StorageService
    ) {
    }

    /**
     * Conver array to CSV string
     * @param {any[]} array
     * @returns {string}
     */
    private static arrayToCSV(array: { [key: string]: string }[]): string {
        const newLine: string = "\r\n";
        const separator: string = ",";
        let str: string = "";

        for (let i: number = 0; i < array.length; i++) {
            let line: string = "";
            for (const index in array[i]) {
                if (array[i].hasOwnProperty(index)) {
                    if (line !== "") {
                        line += separator;
                    }
                    line += "\"" + array[i][index].replace(/"/g, "'") + "\"";
                }
            }

            str += line + newLine;
        }

        const headers: string = Object.keys(array[0]).join(separator) + newLine;

        return headers + str;
    }

    /**
     * Convert dot path to oject path
     * @param {Object} object
     * @param {string} path
     * @param defaultValue
     * @returns {any}
     */
    public static dotToObjectPath(object: Object, path: string, defaultValue: any = null): any {
        const pathArray: string[] = path.split(".");
        let value: any = object;
        for (const step of pathArray) {
            if (value && value[step] !== undefined) {
                value = value[step];
            } else {
                return defaultValue;
            }
        }
        return value;
    }

    /**
     * Get an item from an array or object using "dot" notation.
     *
     * @param $target
     * @param $key
     * @param $default
     * @return any
     */
    public static get_value($target: any, $key: string[] | string, $default: any = null): any {

        if ($key === null) {
            return $target;
        }

        $key = Array.isArray($key) ? [...$key] : $key.split(".");

        let $segment: string;

        while (($segment = $key.shift()) !== undefined) {


            if ($segment === "*") {

                if (!Array.isArray($target)) {
                    return $default;
                }

                const $result: any[] = [];

                for (const $item of $target) {
                    $result.push(HelpersService.get_value($item, $key));
                }

                return $key.includes("*") ? [].concat.apply([], $result) : $result;
            }

            if ($target && $target[$segment] !== undefined) {
                $target = $target[$segment];
            } else {
                return $default;
            }
        }
        return $target;
    }

    /**
     * Get random string
     * @returns {string}
     */
    public static randomString(): string {
        return Math.random().toString(36).replace(/[^a-z]+/g, "");
    }

    public static arrayDistinct(items: any[]): any[] {
        return Array.from(new Set(items).values());
    }

    /**
     * Export csv
     * @param data
     * @param {string} filename
     */
    public static exportCsv(data: { [key: string]: string }[], filename: string): void {

        const csvContent: string = HelpersService.arrayToCSV(data);
        const exportedFilename: string = filename + ".csv" || "export.csv";
        const blob: Blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
        if (window.navigator.msSaveBlob) { // IE 10+
            window.navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link: any = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url: string = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    public static stickerPopUp(content: string): void {
        const popupWin: any = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
        try {
            popupWin.focus();
            popupWin.document.open();
            popupWin.document.write(`
              <html lang="en-US">
                <head>
                  <title></title>
                  <link rel="stylesheet" href="/assets/styles/sticker.css">
                </head>
                <body onload="window.print();window.close()">${content}</body>
              </html>`
            );

            popupWin.document.close();
        } catch (e) {
            alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
        }
    }

    public static getConfigNames(item: Warehouse.IInventory): string {
        if (!item) {
            return "";
        }
        if (item.configurations) {
            return item.configurations;
        } else if (item.related_configurations && Array.isArray(item.related_configurations)) {
            return (item.related_configurations.map((conf: { name: string }): string => conf.name)).join(", ");
        }
        return "";
    }

    public static notFalseValidator(control: AbstractControl): ValidationErrors | null {
        return control.value === false ? {is_false: true} : null;
    }

    public static includes(string: string, needle: string[] | string, position: number = 0): boolean {
        if (Array.isArray(needle)) {
            for (const val of needle) {
                if (string.includes(val, position)) {
                    return true;
                }
            }
        } else {
            return string.includes(needle, position);
        }
        return false;
    }

    public static dateTimeFormat(date_time: string): string {
        return moment(date_time).format(
            DATE_FORMATS.display.dateInput
        );
    }

    public static getTimeSlots(divider: 1 | 2 | 4 = 1, for_select: boolean = false): any {
        const times: any[] = [];
        const min: number = 60 / divider;
        for (let h: number = 0; h < 24; h++) {
            for (let m: number = 0; m < divider; m++) {
                const time: string = h.toString().padStart(2, "0") + ":" + (min * m).toString().padEnd(2, "0");
                if (for_select) {
                    times.push({value: time, name: time});
                } else {
                    times.push(time);
                }
            }
        }
        return times;
    }

    public static formatBytes(bytes: number, decimals: number = 2): string {
        if (bytes === 0) {
            return "0 Bytes";
        }

        const k: number = 1024;
        const dm: number = decimals < 0 ? 0 : decimals;
        const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i: number = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    public static stripTags($html: string): string {
        const dom = new DOMParser().parseFromString($html, "text/html");
        return dom.body.textContent;
    }

    public static isExternalUrl(url: string): boolean {
        if (url.includes("http://")) {
            return true;
        } else if (url.includes("https://")) {
            return true;
        }
        return false;
    }

    public static isEmail(value: string): boolean {
        return RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9]{1,61}\.[a-zA-Z0-9-]{0,10}$/).test(value);
    }

    public static getTrackingUrl(courier_url, tracking_number): string {
        if (!courier_url) {
            return null;
        }

        return courier_url.replace("{tracking}", tracking_number);
    }

    public static valOrNull(val: any): any {
        if (!val) {
            return null;
        }

        return val;
    }


    /**
     * get distance using Spherical Law of Cosines
     * @param lat1
     * @param lng1
     * @param lat2
     * @param lng2
     */
    public static getDistanceKmByCoords(lat1: any, lng1: any, lat2: any, lng2: any): number {
        if (!lat1 || !lng1 || !lng2 || !lat2) {
            return 0;
        }
        const R: number = 6371000;
        const p1: number =   Number(lat1) * Math.PI / 180;
        const p2: number =   Number(lat2) * Math.PI / 180;
        const deltaP: number = p2 - p1;
        const deltaLon: number =   Number(lng2) -   Number(lng1);
        const deltaLambda: number = (deltaLon * Math.PI) / 180;
        const a: number = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
            Math.cos(p1) * Math.cos(p2) *
            Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
        return Math.round(2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R/1000);
    }


    public static setValidatorsFromConfig(formGroup: FormGroup, config: any): void {
        for (const field of Object.keys(config)) {
            const validators = [];

            for (const rule of Object.keys(config[field])) {
                switch (rule) {
                    case "required":
                        validators.push(Validators.required);
                        break;
                    case "maxLength":
                        validators.push(Validators.maxLength(config[field]["maxLength"]));
                        break;
                    case "regex":
                        validators.push(Validators.pattern(config[field]["regex"]));
                        break;
                    default:
                        break;
                }
            }

            if (formGroup.get(field) && validators.length) {
                formGroup.get(field).setValidators(validators);
                formGroup.get(field).updateValueAndValidity();
            }
        }
    }

    /**
     * Difference between two dates
     * @param {string} date1
     * @param {string} date2
     * @param {string} measurementUnit
     * @returns {any}
     */
    public datesDiff(date1: string, date2: string, measurementUnit: Diff): any {
        const date1Obj: Moment = moment(date1);
        const date2Obj: Moment = moment(date2);
        return date1Obj.diff(date2Obj, measurementUnit);
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    public async prepareCountriesList(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["address", "country"], null, {
            data_structure: "select"
        });
        if (Array.isArray(data)) {
            return data;
        } else {
            return [];
        }
    }

    public async getMentionUsers(): Promise<User.IMentionUser[]> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", "list", "mentions"]);
        if (data) {
            return data.map((d: any): User.IMentionUser => {
                return {name: d.name, nickname: d.nickname, avatar: d.avatar, isTeam: !!d.isTeam};
            });
        }
        return [];
    }
}
