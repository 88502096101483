import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UserService} from "../../../../../../common/services/user.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import Hashids from "hashids";
import {environment} from "../../../../../../../environments/environment";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Router} from "@angular/router";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import IRemarkClassification = Order.IRemarkClassification;
import {animate, state, style, transition, trigger} from "@angular/animations";
import {StorageService} from "../../../../../../common/services/storage.service";


@Component({
    selector: "section-remarks-sidebar",
    templateUrl: "remarks-sidebar.component.html",
    styleUrls: [
        "remarks-sidebar.component.scss"
    ],
    animations: [
        trigger("slideInOut", [
            state("full", style({
                height: "*",
            })),
            state("hide", style({
                height: "0"
            })),
            transition("hide => full", animate("400ms ease-in-out")),
            transition("full => hide", animate("400ms ease-in-out"))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RemarksSidebarComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private default_order_remark_type: any;

    public state: Base.IState;

    public showOverlay: boolean = false;

    public isOpen: boolean = false;

    @Input()
    public markRemarkReadOnView: boolean = true;

    @Output()
    public onClose: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public onNewRemark: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("iTicketRef", {static: true})
    public iTicketRef: ElementRef;

    public mentionUsers: User.IMentionUser[] = [];

    public show_system_remarks: FormControl = new FormControl(false);

    public order_ref: string;

    public order_id: number;

    public data: IPagination<any>;

    public noData: boolean = true;

    public remarkTypes: { id: number, name: string }[];

    public remarkClassifications: IRemarkClassification[];

    public remarkSearch: FormControl = new FormControl(null);

    public order_remark_classification_ids: FormControl = new FormControl([]);

    public order_remark_type_ids: FormControl = new FormControl([]);

    public formGroup: FormGroup = new FormGroup({
        "subject": new FormControl(null, []),
        "order_remark_type_id": new FormControl(4, [Validators.required]),
        "order_remark_classification_id": new FormControl(null, []),
        "message": new FormControl(null, [Validators.required]),
        "emergency": new FormControl(false, []),
        "followup": new FormControl(false, []),
        "followup_team": new FormControl(null, []),
        "parent_id": new FormControl(null),
        "attachments": new FormControl([], [])
    });

    public attachments: any[] = [];

    public showForm: string = "hide";

    public maxId: number = 0;

    public followup_teams: any[];

    public whoCanSee: number;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private router: Router,
        private helperService: HelpersService,
        private storageService: StorageService,
        public userService: UserService,
    ) {
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.remarkTypes = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkClassifications(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "classification"]);
        if (data) {
            this.remarkClassifications = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getMentionUsers(): Promise<any> {
        this.mentionUsers = await this.helperService.getMentionUsers();
        this.changeDetectorRef.markForCheck();
    }

    private async getFollowupTeams(): Promise<any> {
        const teamsResponse: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {
                data_structure: "select",
                category: "followup"
            });
        if (teamsResponse.data) {
            this.followup_teams = teamsResponse.data;
            const default_team: any = teamsResponse.data.find((el: any): boolean => el.name === "Call Center");
            if (default_team) {
                this.formGroup.patchValue({
                    followup_team: default_team.value
                });
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    public async show(order_id: number, order_ref: string, forceShow: boolean = false): Promise<any> {
        this.order_id = order_id;
        this.order_ref = order_ref;

        if (forceShow) {
            this.isOpen = true;
        }
        this.data = null;
        this.changeDetectorRef.markForCheck();

        this.getFollowupTeams();
        this.getData();
        this.getRemarkTypes();
        this.getRemarkClassifications();
        this.getMentionUsers();
    }

    /**
     * Get order remarks
     * @returns {Promise<any>}
     */
    public async getData(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();

        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.order_id}/remarks`, {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by: this.remarkSearch.value,
                search_in: ["subject", "message"],
                show_system_remarks: this.show_system_remarks.value,
                order_remark_classification_ids: this.order_remark_classification_ids.value,
                order_remark_type_ids: this.order_remark_type_ids.value,
                relations: [
                    "OrderRemarkType:id,name",
                    "Owner:id,name,email,avatar",
                    "Child.Attachments",
                    "Child.Owner:id,name,email,avatar",
                    "Attachments",
                    "Requests:id,status,order_remark_id,request_kind_id",
                    "Requests.RequestKind:id,name"
                ]
            });

        if (data) {
            this.data = data;
            if (this.data.data && this.data.data.length > 0) {
                this.noData = false;

                this.isOpen = true;
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Set max remark id
     * @param {number} id
     */
    public setMaxId(id: number): void {
        if (id > this.maxId) {
            this.maxId = id;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit new remark
     * @returns {Promise<any>}
     */
    public async submitRemark(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["remark", "order", this.order_ref], {...this.formGroup.getRawValue()});

        if (response.type as string === "success") {
            if (this.formGroup.get("followup").value === true) {
                const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                    ["request", "kind"], {}, {
                        category: "followup"
                    });
                if (data) {
                    const kinds: any = data.filter((val: any): boolean => {
                        return val.name === "24Desk";
                    });
                    if (kinds[0] && this.formGroup.get("followup_team").value !== null) {
                        await this.apiService.request(Api.EMethod.Post, ["request"],
                            {
                                assignee_id: null,
                                description: this.formGroup.get("message").value,
                                files: this.attachments.map((el: any): any => {
                                    el.url = el.path;
                                    return el;
                                }),
                                link_to_order: !!this.router.url,
                                order_remark_type_id: 4,
                                order_remark_classification_id:
                                this.formGroup.get("order_remark_classification_id").value,
                                priority: "Low",
                                request_kind_id: kinds[0].id,
                                status: "New",
                                team_id: this.formGroup.get("followup_team").value,
                                timeline_from: null,
                                timeline_to: null,
                                title: "Followup order " + this.order_ref,
                                type: "followup",
                                url: this.router.url,
                            });
                    }
                    this.changeDetectorRef.markForCheck();
                }
            }
            this.formGroup.reset();
            this.formGroup.patchValue({
                attachments: [],
                order_remark_type_id: this.default_order_remark_type ? parseInt(this.default_order_remark_type) : 4
            });
            this.showForm = "hide";
            this.changeDetectorRef.markForCheck();
            this.getData();

            this.onNewRemark.emit();
        }
        this.attachments = [];
        this.spinnerService.hide();
    }

    public async addAttachment(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["remark", "order", this.order_ref, "remark_attachment"]
        });

        if (response && response.value && response.value.data && response.value.data.id) {
            this.formGroup.patchValue(
                {attachments: (this.formGroup.get("attachments").value ?? []).concat([response.value.data.id])}
            );
            this.attachments.push(response.value.data);
            this.toastService.show(response.value.message, "success");
            this.changeDetectorRef.markForCheck();
        }
    }

    public async removeAttachment(index: number, id: string): Promise<any> {
        this.spinnerService.show();
        this.attachments.splice(index, 1);
        const formIndex: any = this.formGroup.value.attachments.indexOf(id);
        if (formIndex > -1) {
            this.formGroup.patchValue({
                attachments: this.formGroup.get("attachments").value.filter((elem: any): boolean => {
                    return elem !== id;
                })
            });
        }
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["user", "attachments"], {id: id});
        if (response) {
            this.toastService.show(response.message, response.type as string);
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Show specific icon depending on attachment extension
     * @param {string} extension
     * @returns {string}
     */
    public getIconByExt(extension: string): string {
        switch (extension) {
            case "png":
            case "jpg":
            case "gif":
                return "image";
            default:
                return "insert_drive_file";
        }
    }

    public makeHashId(remark_type_id: number): string {
        const hashids = new Hashids(environment.hash.salt, environment.hash.length);
        return hashids.encode([this.order_id, remark_type_id]);
    };

    public copyITicket(remark_type_id: number): void {
        const hash = this.makeHashId(remark_type_id);
        this.iTicketRef.nativeElement.value = "lTicket:" + hash;
        this.iTicketRef.nativeElement.select();
        document.execCommand("Copy");
        this.toastService.show("Copied to clipboard: <b>lTicket:" + hash + "</b>", "success");
    }

    public resetForm(): void {
        this.formGroup.reset();
        this.showForm = "hide";
        this.attachments = [];

        this.formGroup.get("subject").enable();
        this.formGroup.get("order_remark_type_id").enable();
        this.formGroup.get("emergency").enable();
        this.formGroup.get("followup").enable();
        this.formGroup.get("order_remark_classification_id").enable();

        this.changeDetectorRef.markForCheck();
    }

    public addWhInstructions(): void {
        this.resetForm();

        this.formGroup.get("subject").setValue("Warehouse instructions");
        this.formGroup.get("subject").disable({onlySelf: true});

        this.formGroup.get("order_remark_type_id").setValue(2);
        this.formGroup.get("order_remark_type_id").disable({onlySelf: true});

        this.formGroup.get("emergency").disable({onlySelf: true});
        this.formGroup.get("followup").disable({onlySelf: true});
        this.formGroup.get("order_remark_classification_id").disable({onlySelf: true});

        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.state = AppStateService.getState();

        if (this.whoCanSee) {
            this.formGroup.get("order_remark_type_id").setValue(this.whoCanSee);
        } else if (this.userService.data
            && this.userService.data.roles
            && this.userService.data.roles.length > 0
            && this.userService.data.roles[0].order_remark_type_id) {
            this.default_order_remark_type = this.userService.data.roles[0].order_remark_type_id;
            this.formGroup.get("order_remark_type_id").setValue(parseInt(this.default_order_remark_type));
        }

        if (this.storageService.get("show_system_remarks")) {
            this.show_system_remarks.setValue(this.storageService.get("show_system_remarks"));
        }

        this.remarkSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getData();
            });

        this.show_system_remarks.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: boolean): void => {
                this.storageService.set("show_system_remarks", value);
                this.getData();
            });

        if (this.storageService.get("filter_classification_ids")) {
            this.order_remark_classification_ids.setValue(this.storageService.get("filter_classification_ids"));
        }

        this.order_remark_classification_ids.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: boolean): void => {
                this.storageService.set("filter_classification_ids", value);
                this.getData();
            });

        if (this.storageService.get("filter_types_ids")) {
            this.order_remark_type_ids.setValue(this.storageService.get("filter_types_ids"));
        }

        this.order_remark_type_ids.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: boolean): void => {
                this.storageService.set("filter_types_ids", value);
                this.getData();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
