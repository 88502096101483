<h1 class="container-heading">{{ state.action === "add" ? "Add" : "Edit" }} courier service</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup" (submit)="formSubmit()">
            <div class="group flex">

                <div class="quarter">
                    <mat-checkbox formControlName="is_active">
                        Is Active
                    </mat-checkbox>
                </div>

                <div class="quarter">
                    <mat-checkbox formControlName="trackable">Trackable</mat-checkbox>
                </div>

                <div class="quarter">
                    <mat-checkbox formControlName="is_urgent">
                        Is urgent
                    </mat-checkbox>
                </div>

                <div class="quarter">
                    <mat-checkbox formControlName="is_traced_by_warehouse">
                       Traced by WH
                    </mat-checkbox>
                </div>


                <mat-form-field class="full">
                    <mat-label>Service name</mat-label>
                    <input matInput formControlName="service_name" required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description">
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Other Names</mat-label>
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row matChipRemove *ngFor="let chip of chips"
                                      [selectable]="false"
                                      [removable]="true"
                                      (removed)="removeChip(chip)">
                            {{ chip }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input matInput placeholder="New name..."
                               [matChipInputFor]="chipGrid"
                               [matChipInputAddOnBlur]="true"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               (matChipInputTokenEnd)="addChip($event)">
                    </mat-chip-grid>
                </mat-form-field>


                <mat-form-field class="third">
                    <mat-label>Delivery type</mat-label>
                    <mat-select formControlName="delivery_type" required>
                        <mat-option value="int+dom">International and Domestic</mat-option>
                        <mat-option value="dom">Domestic</mat-option>
                        <mat-option value="int">International</mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field class="third">
                    <mat-label>Volumetric weight index</mat-label>
                    <mat-select formControlName="volumetric_weight_index">
                        <mat-option [value]="6000">6000</mat-option>
                        <mat-option [value]="5000">5000</mat-option>
                        <mat-option [value]="4000">4000</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="third">
                    <common-form-select
                            label="Cutt off"
                            [value]="formGroup.value.cutt_off"
                            [required]="true"
                            [search]="true"
                            [options]="cutOffTimeSlots"
                            (valueChange)="formGroup.get('cutt_off').setValue($event)">

                    </common-form-select>
                </div>


                <div class="full filler"></div>

                <mat-form-field class="third">
                    <mat-label>Service type</mat-label>
                    <mat-select formControlName="service_type" placeholder="Service type">
                        <mat-option value="DAYS">Days</mat-option>
                        <mat-option value="NBD">NBD</mat-option>
                        <mat-option value="HOURS">Hours</mat-option>
                        <mat-option value="OTHERS">Others</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="formGroup.value.service_type === 'HOURS'">
                    <mat-form-field class="third">
                        <mat-label>Hours to sla</mat-label>
                        <input matInput type="number" formControlName="hours_to_sla"
                               required>
                    </mat-form-field>

                    <div class="filler third">
                        Note: values in hours
                    </div>

                </ng-container>

                <ng-container *ngIf="formGroup.value.service_type === 'DAYS'">
                    <mat-form-field class="third">
                        <mat-label>Days</mat-label>
                        <input matInput type="number" formControlName="days" required>
                    </mat-form-field>

                    <div class="third">
                        <common-form-select
                                label="Arrives by"
                                [value]="formGroup.value.arrives_by"
                                [required]="true"
                                [search]="true"
                                [options]="arriveByTimeSlots"
                                (valueChange)="formGroup.get('arrives_by').setValue($event)">

                        </common-form-select>
                    </div>
                </ng-container>

                <div class="full"></div>
                <mat-form-field class="third">
                    <mat-label>Fuel surcharge</mat-label>
                    <input matInput type="number" formControlName="fuel_surcharge" min="0">
                </mat-form-field>
            </div>

            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!formGroup.valid">
                    Save
                </button>

                <button type="button" mat-raised-button (click)="returnToList()">
                    Cancel
                </button>
            </div>
        </form>

        <div *ngIf="logsTableSettings" style="padding-top: 30px">
            <h3 class="mat-h3">Change log</h3>
            <common-table2 #logTable [settings]="logsTableSettings" [search]="false"></common-table2>
        </div>
    </mat-card-content>
</mat-card>