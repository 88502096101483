    <form [formGroup]="notesForm">
      <mat-card formArrayName="notes">
        <div class="group flex"*ngFor="let note of notes.controls; let i=index" [formGroupName]="i">
          <mat-card-content>
  
            <mat-form-field class="full">
              <mat-label>Content</mat-label>
              <textarea matInput formControlName="content" placeholder="Note Content"></textarea>
            </mat-form-field>
  
            <mat-form-field class="full">
              <mat-label>Usage</mat-label>
              <mat-select formControlName="usage" multiple>
                <mat-option *ngFor="let option of noteUsage" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template [ngIf]="conditionalParameters?.length > 0">
              <div formGroupName="parameters">
                <div *ngFor="let conditionalParameter of conditionalParameters">
                  <mat-form-field class="full" *ngIf="isUsageIncluded(note.get('usage').value, conditionalParameter.usage)">
                    <mat-label>{{conditionalParameter.label}}</mat-label>
                    <mat-select [formControlName]="conditionalParameter?.formControlName" [multiple]="conditionalParameter.type === 'multiselect'">
                      <mat-option *ngFor="let option of conditionalParameter.options" [value]="option.id">
                        {{option.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </ng-template>

            <div class="pull-right">
                <button mat-raised-button color="accent" type="button"
                    (click)="handleSaveNote(i)"
                    [disabled]="!note.dirty">
                    Save Note
                </button>

                <button mat-raised-button color="warn" type="button"
                    (click)="handleRemoveNote(i)">
                    Remove Note
                </button>
            </div>
  
          </mat-card-content>
          <mat-divider></mat-divider>
        </div>
      </mat-card>

      <div class="actions">
        <button mat-raised-button color="accent" type="button" (click)="addNote()">Add Note</button>
        <button mat-button type="button" *ngIf="showCancel" (click)="cancel()">Cancel</button>
      </div>
      
    </form>