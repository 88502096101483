import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {FileUploadComponent} from "../../file-upload/file-upload.component";
import {Modal, ModalService} from "../../../../section/services/modal.service";

@Component({
    selector: "common-form-file-upload",
    template: `
        <button type="button" mat-raised-button [color]="required && !value ? 'warn' : 'primary'" (click)="upload()">
            {{ label }} {{ required ? "*" : "" }}
        </button>
        <div class="file-name padding-top-5" *ngIf="name">
            <span [title]="name" (click)="openFile()" class="pointer"><b>File name:</b> {{ name }}</span>
            <mat-icon class="vertical-middle text-error pointer" title="Clear" (click)="clearFile()">close</mat-icon>
        </div>
        <div class="image text-center" *ngIf="value">
            <img [src]="value" alt="" style="max-height: 200px; max-width: 100%; height: auto; width: auto;">
        </div>
    `,
    styleUrls: [
        "file-upload.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CommonFormFileUploadComponent implements OnInit {

    public name: string = null;

    @Input()
    public label: string = "Choose file";

    @Input()
    public url: string[] = ["user", "file"];

    @Input()
    public accept: string[] = ["png", "jpg", "jpeg"];

    @Input()
    public value: string;

    @Input()
    public required: boolean = false;

    @Output()
    public file: EventEmitter<string> = new EventEmitter();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService
    ) {
    }


    public async upload(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: this.url,
            accept: this.accept
        });

        if (response && response.value) {
            this.file.emit(response.value.data.path);
            this.value = response.value.data.path;
            this.name = response.value.data.name;
            this.changeDetectorRef.markForCheck();
        }
    }

    public clearFile(): void {
        this.file.emit(null);
        this.value = null;
        this.name = null;
        this.changeDetectorRef.markForCheck();
    }

    public openFile():void{
        if(!this.value) {
            return;
        }

        window.open(this.value, "_blank");
    }

    public ngOnInit(): void {
        if(this.value){
            this.name  = this.value.split("/").pop();
        }
    }
}
