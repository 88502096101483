import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {StorageService} from "../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";


@Component({
    selector: "section-auto-followups-list",
    template: `
        <h1 class="container-heading">Auto followups</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div>
                        <button mat-raised-button color="accent" type="button" *userAllowed="['add_auto_followups']"
                                (click)="add()">
                            Add
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AutoFollowupsListComponent implements OnInit, Base.IComponent {

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }


    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "mBa36t5f6BZ",
            columns: [
                {
                    data: "title",
                    title: "Title"
                },
                {
                    data: "event",
                    title: "Event"
                },
                {
                    data: "id",
                    title: "Service Level",
                    exportable: false,
                    sortable: false,
                    render: (row: any): string => {
                        return row.service_levels.map((sl: Order.IServiceLevel): string => {
                            return sl.name;
                        });
                    }
                },
                {
                    data: "id",
                    title: "Customers",
                    exportable: false,
                    sortable: false,
                    render: (row: any): string => {
                        return row.customers.map((c: any): string => {
                            return c.name;
                        });
                    }
                },
                {
                    data: "id",
                    title: "Hubs",
                    exportable: false,
                    sortable: false,
                    render: (row: any): string => {
                        return row.hubs.map((c: any): string => {
                            return c.customers_inventory_name + " " + c.customers_sub_inventory;
                        });
                    }
                },
                {
                    data: "team.name",
                    title: "Team"
                },
                {
                    data: "request_kind.name",
                    title: "Kind"
                },
                {
                    data: "order_remark_type.name",
                    title: "Who can see"
                }
            ],
            api: {
                url: ["auto-followups"],
                query: {
                    relations: [
                        "ServiceLevels:id,name",
                        "RequestKind:id,name",
                        "Team:id,name",
                        "OrderRemarkType:id,name",
                        "Customers:id,name",
                        "Hubs:id,customers_inventory_name,customers_sub_inventory"
                    ]
                }
            },
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            this.state.component,
                            "view",
                            "id",
                            row.id
                        ]);
                    }
                }
            ]
        };
        this.changeDetectorRef.markForCheck();
    }


    public add(): void {
        AmplitudeService.eventClick("Add auto followups");
        this.router.navigate([
            this.state.section,
            this.state.component,
            "add"
        ]);
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "followups-auto",
            actions: {
                "browse": ["browse_auto_followups"]
            }
        };
    }
}
