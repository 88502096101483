import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Api, ApiService} from "../../../services/api.service";
import {ReplaySubject} from "rxjs";
import {Form} from "../../../interfaces/form.interface";
import {MatSelect} from "@angular/material/select";
import {Api3Service} from "../../../services/api3.service";
import {AppStateService} from "../../../services/app-state.service";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "common-form-select",
    template: `
        <mat-form-field style="width: 100%">
            <mat-label *ngIf="label">{{ label }}</mat-label>
            <mat-select
                [formControl]="formControl"
                [matTooltip]="label && label.length > 80 ? label : null"
                matTooltipPosition="right"
                [title]="selected_option_name ? selected_option_name : ''"
                [multiple]="multiple"
                [required]="required"
                [panelClass]="panelClass"
                (closed)="closed()">


                <mat-option *ngIf="search" disabled  class="select-search-option">

                    <div class="select-search" [class.select-all]="selectAll">
                        <mat-checkbox *ngIf="selectAll" color="accent"
                                      class="mat-select-search-toggle-all-checkbox"
                                      (change)="allSelect()"
                        ></mat-checkbox>

                        <input type="text" [formControl]="optionSearch"
                            [placeholder]="'Search'"
                            (keydown)="onKeydown($event)">
                    </div>
                </mat-option>

                <mat-option *ngIf="!multiple && !required" [value]="null">{{ nullValueName }}</mat-option>

                <ng-content></ng-content>

                <ng-container *ngFor="let option of filteredOptions | async">
                    <mat-option *ngIf="option" [value]="option.value" [disabled]="!!option.disabled"
                        [class]="option.cssClass"
                    >
                        {{ option.name }}
                    </mat-option>
                </ng-container>

                <ng-content select="[options_after]"></ng-content>
            </mat-select>
            <common-form-error [control]="formControl"></common-form-error>
        </mat-form-field>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["select.component.scss"],
})
export class CommonFormSelectComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _options: ISelectOption[] = [];

    public formControl: FormControl;

    public optionSearch: FormControl = new FormControl(null);

    public filteredOptions: ReplaySubject<ISelectOption[]> = new ReplaySubject(1);

    public scrollSize: number = 5;

    public selected_option_name: string = "";

    @Input()
    public panelClass: string = "";

    @Input()
    public selectAll: boolean = false;

    @Input()
    public label: string;

    @Input()
    public value: any = null;

    @Input()
    public options: any[] = [];

    @Input()
    public required: boolean = false;

    @Input()
    public search: boolean = false;

    @Input()
    public exactSearch: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public url: string;

    @Input()
    public url3: string;

    @Input()
    public nullValueName: string = "None";

    @Input()
    public multiple: boolean = false;

    @Input()
    public selectedOnTop: boolean = true;

    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter();

    @ViewChild(MatSelect, {static: true})
    public matSelect: MatSelect;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public apiService: ApiService,
        public api3Service: Api3Service
    ) {
    }

    private prepareOptions(options?: any[]): void {
        if (this.url || this.url3) {
            this.getOptions();
        } else {
            this._options = (options ? options : this.options).map((val: any): any => {
                if (typeof val === "string" || typeof val === "number") {
                    return {value: val, name: "" + val};
                } else if (val === null) {
                    return {value: val, name: ""};
                }
                return val;
            });
            this.sortSelectedOptions(this.value);

            this.scrollSize = this.calcItemsSize(this._options);
            this.filteredOptions.next(this._options);
        }
    }

    private async getOptions(): Promise<any> {
        if (this.disabled) {
            return;
        }
        let res: Api.IResponse;

        if (this.url) {
            const url: { path: string[], query: any } = this.prepareUrl();
            res = await this.apiService.request(Api.EMethod.Get, url.path,
                {}, {
                    data_structure: "select",
                    ...url.query
                });
        } else {
            res = await this.api3Service.get(`${AppStateService.getState().section}/${this.url3}`, {
                data_structure: "select"
            });
        }

        if (res.data) {
            this._options = res.data;
            if (this.value) {
                this.sortSelectedOptions(this.value);
            }
            this.filteredOptions.next(this._options);
            this.scrollSize = this.calcItemsSize(this._options);
            this.changeDetectorRef.markForCheck();
        }
    }

    private sortSelectedOptions(values: any[]): void {
        if (!values || !this.selectedOnTop) {
            return;
        }
        if (!Array.isArray(values)) {
            values = [values];
        }

        values.forEach((val) => {
            const index: number = this._options.findIndex((option: ISelectOption): boolean => {
                return option.value === val;
            });
            if (index === -1) {
                return;
            }
            const opt = this._options[index];
            this._options.splice(index, 1);
            this._options.unshift(opt);
        });
    }

    private setDisabledState(is_disabled: boolean = false): void {
        if (is_disabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
            this.prepareOptions();
        }
        this.changeDetectorRef.markForCheck();
    }

    private calcItemsSize(items: ISelectOption[]): number {
        const l: number = items.length;
        return l > 5 ? 5 : l + (!this.multiple && !this.required ? 1 : 0);
    }

    private prepareUrl(): { path: string[], query: any } {
        const url_array: string[] = this.url.split("?", 2);

        const path: string[] = url_array[0].split("/")
            .filter((val: string): boolean => val.trim() !== "");

        const query: any = {};
        if (url_array.length > 1) {
            const queryParams: string[] = url_array[1].split("&");
            for (const param of queryParams) {
                const [key, val]: string[] = param.split("=", 2);
                query[key] = val;
            }
        }
        return {path, query};
    }

    public allSelect(): void {
        let select: any[] = [];

        if (this.search) {
            select = this._options.filter((option: { name: string }): boolean => {
                if (!this.optionSearch.value) {
                    return true;
                }

                return option.name.toLowerCase().includes(this.optionSearch.value.toLowerCase());
            });
        } else {
            select = this._options;
        }
        if (Array.isArray(this.formControl.value) && select.length === this.formControl.value.length) {
            this.formControl.setValue([]);
        } else {
            this.formControl.setValue(select.map((option: { value: any }): any => option.value));
        }

        this.changeDetectorRef.markForCheck();
    }

    public closed(): void {
        setTimeout((): void => {
            this.onClose.emit(this.formControl.value);
        }, 10);
    }

    public onKeydown(event: KeyboardEvent): void {
        if (event.code === "Space") {
            event.stopPropagation();  // Prevent space from toggling the checkboxes
          }
    }


    public ngOnInit(): void {

        if (this.multiple && this.value && !Array.isArray(this.value)) {
            this.value = [this.value];
        } else if (this.multiple && !this.value) {
            this.value = [];
        }

        this.formControl = new FormControl(this.value, this.required ? [Validators.required] : []);

        this.formControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe((value: any): void => {
                this.valueChange.emit(value);

                this.selected_option_name = this._options
                    .find((option: ISelectOption) => option && value == option.value)?.name;
                this.changeDetectorRef.markForCheck();
            });

        this.formControl.setValue(this.value);

        this.setDisabledState(this.disabled);

        if (this.search) {
            this.optionSearch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {

                value = value.toLowerCase();

                const opts: ISelectOption[] = this._options.filter((option: ISelectOption): boolean => {
                    if (!option.name) {
                        return false;
                    }
                    if (!value) {
                        return true;
                    }
                    if (this.exactSearch) {
                        return option.name.toLowerCase() === value;
                    }
                    return option.name.toLowerCase().includes(value);
                });

                this.scrollSize = this.calcItemsSize(opts);
                this.filteredOptions.next(opts);
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.value && !changes.value.firstChange) {
            this.formControl.setValue(changes.value.currentValue);
        }

        if (changes && changes.options && !changes.options.firstChange) {
            this.prepareOptions(changes.options.currentValue);
        }

        if (changes && changes.disabled && !changes.disabled.firstChange) {
            this.setDisabledState(changes.disabled.currentValue);
        } else if (changes && changes.url && !changes.url.firstChange) {
            this.getOptions();
        }
    }
}
