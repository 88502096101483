<common-confirm></common-confirm>

<h1 class="container-heading">Report settings</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container">
            <div class="column-1-3">
                <mat-card>
                    <div class="margin-bottom-20">
                        <common-simple-spinner *ngIf="!report"></common-simple-spinner>

                        <ng-container *ngIf="report">
                            <div class="text-center">
                                <img [src]="report.icon" alt="">
                            </div>
                            <h2 class="text-center border-bottom-none">{{ report.name }}</h2>
                            <div class="text-center margin-bottom-10">{{ report.description }}</div>
                            <div class="text-center margin-bottom-10" *ngIf="report.admin_can_see">
                                <button mat-raised-button color="accent" (click)="export()">
                                    Export
                                </button>
                            </div>
                            <mat-divider inset class="margin-bottom-10"></mat-divider>
                            <div><b>Key: </b>{{ report.key }}</div>
                            <div><b>Job name: </b>{{ report.job }}</div>
                        </ng-container>

                    </div>

                    <mat-list class="nav-list">
                        <ng-container *ngIf="report?.partner_can_see">
                            <mat-divider></mat-divider>
                            <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                                <mat-icon matListItemIcon>people</mat-icon>
                                <p matListItemTitle>Partners</p>
                            </mat-list-item>
                        </ng-container>
                        <ng-container *ngIf="report?.warehouse_can_see">
                            <mat-divider></mat-divider>
                            <mat-list-item (click)="showSection = 2" [class.active]="showSection === 2">
                                <mat-icon matListItemIcon>business</mat-icon>
                                <p matListItemTitle>Warehouses</p>
                            </mat-list-item>
                        </ng-container>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 3" [class.active]="showSection === 3">
                            <mat-icon matListItemIcon>schedule</mat-icon>
                            <p matListItemTitle>Scheduled tasks</p>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <h2>Selected partners:</h2>

                    <common-simple-spinner *ngIf="!partners"></common-simple-spinner>

                    <ng-container *ngIf="partners">
                        <div class="margin-bottom-20">
                            <mat-form-field class="pull-right">
                                <mat-label>Search</mat-label>
                                <input type="text" matInput #partnerSearch>
                            </mat-form-field>

                            <div class="padding-top-20">
                                <mat-slide-toggle
                                        [disabled]="!userService.validatePermissions('toggle_reports') || report.partner_id"
                                        [checked]="hasAllPartners()"
                                        (change)="selectAllPartners($event)">
                                    Select all partners
                                </mat-slide-toggle>

                            </div>

                            <div class="clear"></div>
                        </div>

                        <mat-card *ngFor="let partner of partners | filter: 'display_name':'like' : partnerSearch.value"
                                  class="margin-bottom-10">
                            <div class="row flex align-center">
                                <div class="max">
                                    <img [src]="partner.icon_path" alt="" height="28" class="vertical-middle">
                                    &nbsp;
                                    {{ partner.display_name }}
                                </div>
                                <mat-slide-toggle
                                        [disabled]="!userService.validatePermissions('toggle_reports') || (report.partner_id && partner.id !==report.partner_id)"
                                        [checked]="hasPartner(partner.id)"
                                        (change)="selectPartners($event, [partner.id])"
                                ></mat-slide-toggle>
                            </div>

                        </mat-card>

                    </ng-container>

                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 2">
                <mat-card>
                    <common-simple-spinner *ngIf="!threepls"></common-simple-spinner>

                    <h2>Selected warehouses:</h2>

                    <div class="margin-bottom-20">
                        <mat-form-field class="pull-right">
                            <mat-label>Search</mat-label>
                            <input type="text" matInput #threeplSearch>
                        </mat-form-field>

                        <div class="padding-top-20">
                            <mat-slide-toggle
                                    [disabled]="!userService.validatePermissions('toggle_reports')"
                                    [checked]="hasAllWarehouses()"
                                    (change)="selectAllWarehouses($event)">
                                Select all warehouses
                            </mat-slide-toggle>

                        </div>

                        <div class="clear"></div>
                    </div>

                    <mat-accordion>
                        <mat-expansion-panel
                                *ngFor="let threepl of threepls | filter: 'display_name':'like' : threeplSearch.value">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ threepl.display_name }}
                                    ({{ threepl.properties.checked_count }} of {{ threepl.warehouses.length }} selected)
                                </mat-panel-title>

                                <mat-panel-description class="text-right" *ngIf="threepl.warehouses.length">
                                    <mat-slide-toggle
                                            [disabled]="!userService.validatePermissions('toggle_reports')"
                                            [checked]="hasAllWarehouses(threepl.id)"
                                            (click)="$event.stopPropagation()"
                                            (change)="selectAllWarehouses($event,threepl.id)">
                                    </mat-slide-toggle>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <ng-template matExpansionPanelContent>
                                <div>
                                    <div class="text-right">
                                        <mat-form-field>
                                            <mat-label>Search</mat-label>
                                            <input type="text" matInput #warehouseSearch>
                                        </mat-form-field>
                                    </div>

                                    <mat-card
                                            *ngFor="let warehouse of threepl.warehouses | filter: 'name':'like' : warehouseSearch.value "
                                            class="margin-bottom-10">
                                        <div class="row flex align-center">
                                            <div class="max">
                                                {{ warehouse.name }}
                                            </div>
                                            <mat-slide-toggle
                                                    [disabled]="!userService.validatePermissions('toggle_reports')"
                                                    [checked]="hasWarehouse(warehouse.id)"
                                                    (change)="selectWarehouses($event, [warehouse.id])"
                                            ></mat-slide-toggle>
                                        </div>

                                    </mat-card>
                                </div>
                            </ng-template>

                        </mat-expansion-panel>

                    </mat-accordion>

                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 3">
                <mat-card>
                    <h2>Scheduled tasks:</h2>
                    <common-table2 *ngIf="scheduledTasksTable" [settings]="scheduledTasksTable"></common-table2>
                </mat-card>
            </div>


        </div>

    </mat-card-content>
</mat-card>
