<common-confirm></common-confirm>
<common-simple-spinner *ngIf="!shipments"></common-simple-spinner>
<ng-template [ngIf]="shipments && shipments.data && shipments.data.length > 0">
    <mat-divider class="clear"></mat-divider>


    <h2 class="mat-h2 heading border-bottom-none">
        Order shipments
    </h2>
    <div class="shipments clear">
        <mat-card *ngFor="let shipment of shipments.data; let shipmentIndex = index">
            <div class="title">
                <div class="text-center margin-bottom-20">
                    <ng-template [ngIf]="shipment.type === 'outbound'">
                        <mat-icon style="color:red" title="{{shipment.ref}}">vertical_align_top</mat-icon>
                    </ng-template>
                    <ng-template [ngIf]="shipment.type === 'inbound'">
                        <mat-icon style="color:green" title="{{shipment.ref}}">vertical_align_bottom</mat-icon>
                    </ng-template>

                    <b>{{ shipment.type | ucfirst }}</b>

                    <ng-template [ngIf]="shipment.type === 'outbound'">
                        <mat-icon style="color:red" title="{{shipment.ref}}">vertical_align_top</mat-icon>
                    </ng-template>
                    <ng-template [ngIf]="shipment.type === 'inbound'">
                        <mat-icon style="color:green" title="{{shipment.ref}}">vertical_align_bottom</mat-icon>
                    </ng-template>
                </div>

                <div class="buttons">
                    <ng-container *userAllowed="'super_admin'">
                        <button *ngIf="shipment.type === 'inbound'
                        && shipment.warehouse_order?.warehouse?.threepl?.slug === 'choice' && shipment.shipping_distance < 201"
                                mat-raised-button color="warn"
                                type="button"
                                (click)="sendChoicePU(shipment)">
                            3PL pickup
                        </button>
                        <button mat-raised-button color="warn"
                                type="button"
                                (click)="sendPrealert(shipment)">
                            3PL pre-alert
                        </button>
                    </ng-container>
                    <button class="edit" mat-raised-button color="accent"
                            *userAllowed="'edit_shipments'"
                            (click)="editShipment(shipment)">
                        Edit
                    </button>
                    <button type="button" mat-raised-button color="primary"
                            *userAllowed="'add_pickup_requests'"
                            (click)="requestPickup(shipment)">
                        Request pickup
                    </button>
                    <button type="button" mat-raised-button color="primary"
                            (click)="showParcels(shipment.id)"
                            [disabled]="!shipment.parcels_count"
                            [matBadge]="shipment.parcels_count"
                            matBadgeColor="accent">
                        Show parcels
                    </button>
                    <button type="button" mat-raised-button color="accent"
                            *userAllowed="'create_shippy_pro_labels'"
                            (click)="createLabel(shipment)">
                        <mat-icon>ballot</mat-icon>
                        Create label
                    </button>
                    <button type="button" class="add-transaction" mat-raised-button color="primary"
                            *userAllowed="'add_courier_transactions'"
                            (click)="addTransaction(shipment)">
                        <mat-icon>local_shipping</mat-icon>
                        Tracking#/AWB#
                    </button>
                </div>

                <div class="group flex margin-bottom-20">
                    <div class="half" style="padding-right: 20px">


                        <div *ngIf="shipment.from_address" class="margin-bottom-20">
                            <mat-icon>flight_takeoff</mat-icon>
                            <span [innerHTML]="shipment.from_address.address"></span>
                            {{ shipment.from_address.address2 }},
                            {{ shipment.from_address.city }},
                            {{ shipment.from_address.zip }}
                            {{ shipment.from_address.state }}
                            {{ shipment.from_address.country }}
                        </div>

                        <div *ngIf="shipment.courier_service_price" class="margin-bottom-20 shipment-info">
                            <i class="fa fa-arrows-v"></i>
                            <span>
                                {{ shipment.courier_service_price.distance_text }}
                            </span>
                            &nbsp;|&nbsp;
                            <span>
                                <i class="fa fa-money"></i>
                                {{ shipment.courier_service_price.price }}
                                {{ shipment.courier_service_price.currency }}
                            </span>
                            &nbsp;|&nbsp;
                            <span>
                                <i class="fa fa-tachometer"></i>
                                {{ shipment.courier_service_price.total_weight }} kg
                            </span>
                        </div>

                        <div *ngIf="shipment.to_address" class="margin-bottom-20">
                            <mat-icon>flight_land</mat-icon>
                            <span [innerHTML]="shipment.to_address.address"></span>
                            {{ shipment.to_address.address2 }},
                            {{ shipment.to_address.city }},
                            {{ shipment.to_address.zip }}
                            {{ shipment.to_address.state }}
                            {{ shipment.to_address.country }}
                        </div>


                        <div *ngIf="schedule_date_range" class="shipment-info">
                            <b>
                                <mat-icon title="Original ETA">av_timer</mat-icon>
                            </b>
                            {{ schedule_date_range }}
                        </div>

                        <div *ngIf="!schedule_date_range && shipment.courier_service_price">
                            <span matTooltip="Original ETA">
                                <mat-icon>av_timer</mat-icon>
                                {{ shipment.courier_service_price.eta }}
                            </span>

                            <ng-container *ngIf="shipment.type === 'outbound'">
                                <button *userAllowed="'allow_edit_shipment_eta'"
                                        class="edit" mat-icon-button
                                        (click)="editEta(shipment)">
                                    <mat-icon title="Edit">edit</mat-icon>
                                </button>
                            </ng-container>
                        </div>

                        <div *ngIf="shipment.delivery_date">
                            <mat-icon title="Delivery date">gavel</mat-icon>
                            {{ shipment.delivery_date }}
                        </div>

                    </div>

                    <div class="half" *userAllowed="'browse_warehouse_orders'">
                        <mat-card *ngIf="shipment.warehouse_order">
                            <section-order-warehouse-order [orderData]="orderData" [shipment]="shipment">
                            </section-order-warehouse-order>
                        </mat-card>

                        <ng-container *ngIf="!shipment.warehouse_order">
                            <mat-card *userAllowed="'add_warehouse_orders'" class="text-center">
                                <button mat-raised-button
                                        color="accent"
                                        (click)="createWarehouseOrder(shipment)">
                                    Create warehouse order
                                </button>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
            </div>


            <ng-template [ngIf]="shipment.courier_transactions && shipment.courier_transactions.length > 0">

                <mat-divider inset class="margin-bottom-20"></mat-divider>

                <div class="order-tracks">
                    <ng-template ngFor let-transaction let-courierIndex="index"
                                 [ngForOf]="shipment.courier_transactions">
                        <mat-card class="content">
                            <div class="pull-right">
                                <button mat-raised-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <a mat-menu-item (click)="trackingActionHandler('add_status', transaction)">
                                        <mat-icon>add</mat-icon>
                                        Add status
                                    </a>
                                    <a mat-menu-item (click)="trackingActionHandler('change_shipped_at', transaction)">
                                        <mat-icon>access_time</mat-icon>
                                        Edit shipped at
                                    </a>
                                    <a mat-menu-item (click)="trackingActionHandler('change', transaction)">
                                        <mat-icon>edit</mat-icon>
                                        Change tracking
                                    </a>
                                    <a mat-menu-item *userAllowed="'edit_tracking_price'"
                                       (click)="trackingActionHandler('edit_price', transaction)">
                                        <mat-icon>monetization_on</mat-icon>
                                        Edit price
                                    </a>
                                    <a mat-menu-item *userAllowed="'browse_admin'"
                                       (click)="trackingActionHandler('delete', transaction)">
                                        <mat-icon>delete</mat-icon>
                                        Remove tracking
                                    </a>
                                </mat-menu>
                            </div>
                            <div class="courier" *ngIf="transaction.courier">
                                <div class="courier-logo">
                                    <img [src]="transaction.courier.icon_path">
                                </div>
                                <div class="courier-name">
                                    {{ transaction.courier ? transaction.courier.display_name : '' }}
                                    - {{ transaction.courier_service ? transaction.courier_service.service_name : '' }}
                                </div>
                            </div>
                            <div class="tracking">
                                <div *ngIf="transaction.created_at" class="number">
                                    Created at:
                                    <br>
                                    <b>
                                        {{ transaction.created_at | tz: "Asia/Jerusalem": null :"DD.MM.yyyy HH:mm": true }}

                                        <ng-container
                                                *ngIf="shipment.to_address?.country_detailed?.timezone && shipment.to_address.country_detailed?.timezone !=='Asia/Jerusalem'">
                                            / {{ transaction.created_at | tz: "Asia/Jerusalem": shipment.to_address.country_detailed?.timezone :"DD.MM.yyyy HH:mm": true }}
                                        </ng-container>
                                    </b>
                                </div>
                            </div>
                            <div class="tracking">
                                <div *ngIf="transaction.shipped_at" class="number">
                                    Shipped at:
                                    <br>
                                    <b>
                                        {{ transaction.shipped_at | tz: "Asia/Jerusalem": null :"DD.MM.yyyy HH:mm": true }}

                                        <ng-container
                                                *ngIf="shipment.to_address?.country_detailed?.timezone && shipment.to_address.country_detailed?.timezone !=='Asia/Jerusalem'">
                                            / {{ transaction.shipped_at | tz: "Asia/Jerusalem": shipment.to_address.country_detailed?.timezone :"DD.MM.yyyy HH:mm": true }}
                                        </ng-container>


                                    </b>
                                </div>
                            </div>
                            <div class="tracking">
                                <div *ngIf="transaction.delivered_at" class="number">
                                    Delivered at:
                                    <br>
                                    <b>
                                        {{ transaction.delivered_at | tz: "Asia/Jerusalem": null :"DD.MM.yyyy HH:mm": true }}
                                        <ng-container
                                                *ngIf="shipment.to_address?.country_detailed?.timezone && shipment.to_address.country_detailed?.timezone !=='Asia/Jerusalem'">
                                            / {{ transaction.delivered_at | tz: "Asia/Jerusalem": shipment.to_address.country_detailed?.timezone :"DD.MM.yyyy HH:mm": true }}
                                        </ng-container>
                                    </b>
                                </div>
                            </div>
                            <div class="tracking">
                                <div class="number">Tracking Number:
                                    <b>{{ transaction.tracking_number }}</b>
                                </div>
                                <div class="status">
                                    <span class="chip {{transaction.status}}">
                                        {{ transaction.status }}
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="transaction.packages">
                                Packages:
                                <b>{{ transaction.packages }}</b>
                            </div>
                            <div *ngIf="transaction.weight">
                                Weight:
                                <b>{{ transaction.weight }} {{ transaction.weight_unit }}</b>
                            </div>

                            <div *ngIf="transaction.messages">
                                Message: <b>{{ transaction.messages }}</b>
                            </div>
                            <div class="expected-date" *ngIf="transaction.expected_delivery">
                                Expected delivery: <b>{{ transaction.expected_delivery | date: "dd.MM.yyyy"}}</b>
                            </div>
                            <div class="pod" *ngIf="transaction.pod">
                                Proof of delivery: <b>{{ transaction.pod }}</b>
                            </div>

                            <div class="buttons">
                                <button mat-raised-button color="primary" type="button"
                                        (click)="showTrackProgress(shipmentIndex,courierIndex)">
                                    Progress
                                </button>
                                <button *ngIf="transaction.status !== 'delivered'" type="button"
                                        mat-raised-button color="primary"
                                        (click)="markAsDelivered(transaction.id)">
                                    Mark as delivered
                                </button>
                                <button mat-raised-button color="success" type="button"
                                        (click)="showTrackContent(shipmentIndex,courierIndex)">
                                    Content
                                </button>

                                <button mat-raised-button *ngIf="transaction.shippy_pro_labels.length"
                                        type="button" color="accent"
                                        (click)="openLabel(transaction.shippy_pro_labels)">
                                    <mat-icon>print</mat-icon>
                                    {{ transaction.shippy_pro_labels.length }}
                                </button>

                                <ng-container *userAllowed="'create_shippy_pro_pickups'">
                                    <button type="button" mat-raised-button color="primary"
                                            *ngIf="transaction.params?.shippy_pro_carrier_id"
                                            [disabled]="transaction.shippy_pro_pickup"
                                            (click)="createShippyProPickup(shipment, transaction)">
                                        <mat-icon>concierge</mat-icon>
                                        Schedule courier pickup
                                    </button>
                                </ng-container>
                            </div>
                        </mat-card>
                    </ng-template>
                </div>
            </ng-template>

            <ng-template [ngIf]="shipment.pickup_requests && shipment.pickup_requests.length > 0">

                <mat-divider inset class="margin-bottom-20"></mat-divider>

                <h3>Pickup requests</h3>
                <ng-template ngFor let-pickup_request
                             [ngForOf]="shipment.pickup_requests">
                    <div style="text-align: left">
                            <span [style.color]="
                                pickup_request.status.name === 'Open' ? 'green'
                                : (pickup_request.status.name === 'Closed' ? 'red' : 'orange')
                            ">
                                <b>[{{ pickup_request.status.name }}]</b>
                            </span>
                        Created by <b>{{ pickup_request.user.name }}</b> scheduled for:
                        <b>{{ pickup_request.from_date }}
                            - {{ pickup_request.to_date }}</b>
                        <button class="edit" mat-icon-button (click)="requestPickup(shipment, pickup_request)">
                            <mat-icon style="line-height: 12px" title="Edit">edit</mat-icon>
                        </button>
                        <br/>
                        <ng-template [ngIf]="pickup_request.remarks">
                            Remarks: {{ pickup_request.remarks }}
                        </ng-template>
                        <div style="padding-top: 10px">
                            <button type="button" mat-raised-button color="accent" (click)="showInfo(pickup_request)">
                                View full info
                            </button>
                        </div>

                    </div>
                </ng-template>
            </ng-template>

        </mat-card>

        <common-pagination [data]="shipments" id="order_shipments"
                           pid="AGMj675kr"
                           (goToPage)="getOrderShipments($event.page, $event.per_page)"></common-pagination>
    </div>
</ng-template>
