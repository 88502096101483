import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {WarehouseTransactionFormComponent} from "../../../../order";
import {
    AvailableLocationsModalComponent,
    BoxItemsListComponent,
    FindLocationByItemModalComponent
} from "../../../index";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";

@Component({
    selector: "section-warehouse-procedures-wizard-inbound-scan-boxes",
    templateUrl: "scan.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardInboundScanBoxesComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private orderRef: string;

    private prevKey: string = null;

    private isModalOpen: boolean = false;

    public serialTemp: string = null;

    public showFreeBoxes: boolean = false;

    @ViewChild("locationCountInput", {static: false})
    public locationCountInput: ElementRef;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    @ViewChild("newLocationName", {static: false})
    public newLocationNameInput: ElementRef;

    public warehouseOrderId: string | number;

    public boxList: IPagination<any>;

    public scannerKey: string;

    public partner: User.IPartner;

    public searchInput: FormControl = new FormControl(null);

    public searchInputLabel: string = "Location";

    public showAddFrom: number = 0;

    public locationTypes: any[];

    public locationFrom: FormGroup = new FormGroup({
        location: new FormControl(null, [Validators.required]),
        warehouse_location_type_id: new FormControl(null, [Validators.required])
    });

    public boxForm: FormGroup = new FormGroup({
        warehouse_location_id: new FormControl(null),
        ref: new FormControl(null, [Validators.required])
    });

    public location: {
        name: string,
        id: number,
        image: string,
        type_id: number,
        warehouse_transactions_count: number;
        hub?: Warehouse.IHub;
    };

    public shipment: Warehouse.IShipment;

    public usedLocations: any[] = [];

    public scannerOnly: boolean = false;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private storageService: StorageService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get shipment
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id]);
        if (data) {
            this.shipment = data;
            this.scannerOnly = this.shipment && this.shipment.partner
                ? this.shipment.partner.properties.disable_manual_scanning
                : false;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get location types list
     */
    private async getLocationTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "type"], {}, {
                data_structure: "select"
            });
        if (data) {
            this.locationTypes = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getLocationsByOrder(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "order", this.orderRef]);
        if (data) {
            this.usedLocations = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Find location if none is specified
     */
    private async findLocation(): Promise<any> {
        this.spinnerService.show();
        const {data, code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse_location", "find"], {ref: this.searchInput.value || this.location.name});

        this.spinnerService.hide();

        if (code === 424) {
            this.showAddFrom = 2;
            this.soundsService.textToSpeech("Location not found. Please scan again or create a new one");
        } else if (data) {
            this.showAddFrom = 0;
            this.location = {
                name: this.searchInput.value || this.location.name,
                id: data.id,
                image: data.warehouse_location_type.icon,
                type_id: data.warehouse_location_type_id,
                warehouse_transactions_count: data.warehouse_transactions_by_box_count
            };
            this.getLocationHub();
            this.soundsService.textToSpeech("Location set");
            this.searchInputLabel = "Location or box";
            if (this.showFreeBoxes) {
                this.getFreeBoxes();
            } else {
                this.getBoxesInLocation();
            }
        }
        this.searchInput.reset();
        this.searchInputRef.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Find location or box by its ref
     */
    private async findItem(): Promise<any> {
        this.spinnerService.show();
        const {data, code, message, error}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["box", "warehouse_order", "" + this.warehouseOrderId, "scan"], {
                ref: this.searchInput.value,
                location: this.location.name,
                serial: this.serialTemp
            }, {type: "inbound"});

        this.spinnerService.hide();
        if (code === 424) {
            if (error.data.serial) {
                this.soundsService.textToSpeech("The serial number is not from the same box");
            } else {
                this.showAddFrom = 1;
                this.locationFrom.patchValue({"location": this.searchInput.value});
                this.boxForm.patchValue({"ref": this.searchInput.value});
                this.soundsService.textToSpeech("New reference detected. Is that location or a box?");
            }
        } else if (message === "This box was already scanned") {
            this.soundsService.textToSpeech("This box has already scanned. The number of boxes" +
                " in this location is " + data.box_count);
            this.toastService.show(message, "error");
        } else if (data) {
            if (data.location) {
                this.location = {
                    name: data.location.location,
                    id: data.location.id,
                    image: data.location.warehouse_location_type.icon,
                    type_id: data.location.warehouse_location_type_id,
                    warehouse_transactions_count: data.warehouse_transactions_by_box_count
                };
                this.soundsService.textToSpeech("Location set");
            } else if (data.box) {
                this.soundsService.textToSpeech("Box successfully inbound");
            }
            if (data.serial) {
                this.serialTemp = this.searchInput.value;
                this.soundsService.textToSpeech("Please scan the additional serial number from the same box");
            } else {
                this.serialTemp = null;
                if (this.showFreeBoxes) {
                    this.getFreeBoxes();
                } else {
                    this.getBoxesInLocation();
                }
            }

            this.toastService.show(message, "success");
        }
        this.searchInput.reset();
        setTimeout((): void => {
            if (!this.isModalOpen) {
                this.searchInputRef.nativeElement.focus();
            }
        }, 1000);
        this.changeDetectorRef.markForCheck();

    }

    /**
     *  Get box list for specified location
     *
     * @param page
     * @param per_page
     */
    private async getFreeBoxes(page: number = 1, per_page: number = null): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "warehouse_order", "" + this.warehouseOrderId], {}, {
                with_location: false,
                type: "inbound",
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            this.boxList = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get box list for specified location
     *
     * @param page
     * @param per_page
     */
    private async getBoxesInLocation(page: number = 1, per_page: number = 10): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "warehouse_location", "" + this.location.id], {}, {
                data_structure: "paginated",
                page,
                per_page,
                type: "inbound",
                order_id: this.shipment.order_id
            });
        if (data) {
            this.boxList = data;

            for (const box of this.boxList.data) {
                box.warehouse_transactions_count = 0;
                for (const transaction of box.warehouse_transactions) {
                    box.warehouse_transactions_count += transaction.quantity;
                }
            }

            this.changeDetectorRef.markForCheck();
        }
    }

    private async getLocationHub(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "" + this.location.id, "hub"]);
        if (data && data.customers_sub_inventory) {
            this.location.hub = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get box list paginated
     *
     * @param page
     * @param per_page
     */
    public getBoxesPaginated(page: number = 1, per_page: number = null): void {
        if (this.showFreeBoxes) {
            this.getFreeBoxes(page, per_page);
        } else {
            this.getBoxesInLocation(page, per_page);
        }
    }

    /**
     * Find location or box
     */
    public async find(): Promise<any> {
        if (!this.location) {
            this.locationFrom.patchValue({"location": this.searchInput.value});
            return this.findLocation();
        } else {
            this.boxForm.patchValue({"ref": this.searchInput.value});
            this.findItem();
        }
    }

    /**
     * Add new location and select it
     */
    public async addLocation(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["warehouse_location"], this.locationFrom.value);
        if (data) {
            this.showAddFrom = 0;
            this.location = {
                name: data.location,
                id: data.id,
                image: data.warehouse_location_type.icon,
                type_id: data.warehouse_location_type_id,
                warehouse_transactions_count: data.warehouse_transactions_by_box_count
            };
            if (!this.showFreeBoxes) {
                this.boxList = null;
                this.getBoxesInLocation();
            } else {
                this.getFreeBoxes();
            }
            this.searchInput.reset();
            this.locationFrom.reset();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Add new box in specified location
     */
    public async addBox(): Promise<any> {
        this.spinnerService.show();
        this.boxForm.get("warehouse_location_id").setValue(this.location.id);
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["box", "warehouse_order", "" + this.warehouseOrderId], this.boxForm.value);
        if (data) {
            this.showAddFrom = 0;
            this.searchInput.setValue(null);
            this.boxForm.reset();
            this.changeDetectorRef.markForCheck();
            this.soundsService.textToSpeech("Box successfully inbound");
            if (this.showFreeBoxes) {
                this.getFreeBoxes();
            } else {
                this.getBoxesInLocation();
                this.addTransaction(data.box);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    /**
     * Add warehouse transaction to box
     * @param box
     */
    public async addTransaction(box: any): Promise<any> {
        this.isModalOpen = true;
        const response: Modal.IResponse = await this.modalService.open(WarehouseTransactionFormComponent, {
            action: "add",
            type: "inbound",
            modalWidth: 600,
            data: {
                box_id: box.id,
                location_id: this.location.id,
                warehouse_order_id: this.warehouseOrderId
            },
            partner: box.partner,
            state: this.state,
            scannerKey: this.scannerKey
        });
        this.isModalOpen = false;

        if (response) {
            this.getBoxesInLocation();
        }
    }

    /**
     * Show items in the box
     * @param box
     */
    public async openBox(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxItemsListComponent,
            {
                box,
                modalWidth: 1000,
                itemsType: this.showFreeBoxes ? "allocation" : "warehouse-transactions"
            });
    }


    /**
     * Set location from used list
     * @param data
     */
    public setLocationFromList(data: any): void {
        this.showAddFrom = 0;
        this.location = {
            name: data.location,
            id: data.id,
            image: data.warehouse_location_type.icon,
            type_id: data.warehouse_location_type_id,
            warehouse_transactions_count: data.warehouse_transactions_by_box_count
        };
        this.getLocationHub();
        this.soundsService.textToSpeech("Location set");
        this.searchInputLabel = "Location or box";
        this.searchInput.reset();
        this.changeDetectorRef.markForCheck();
        if (this.showFreeBoxes) {
            this.getFreeBoxes();
        } else {
            this.getBoxesInLocation();
        }
    }

    /**
     * Clear location
     */
    public clearLocation(): void {
        this.location = null;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Clear scanned serial
     */
    public clearSerial(): void {
        this.serialTemp = null;
        this.changeDetectorRef.markForCheck();
    }

    public async findLocationByItem(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FindLocationByItemModalComponent);

        if (response && response.name === "set") {
            response.value.hub = response.value.inventory_conversion;
            this.setLocationFromList(response.value);
        }
    }

    public async locationList(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(AvailableLocationsModalComponent, {
            orderRef: this.orderRef,
            modalWidth: 1200,
            state: this.state
        });

        if (response) {
            if (response.name === "set") {
                this.setLocationFromList(response.value);
            } else if (response.name === "add") {
                this.addLocation();
            }
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.warehouseOrderId = data.warehouseOrderId;
        this.showFreeBoxes = data.showFreeBoxes;
        this.orderRef = data.orderRef;
        this.scannerKey = data.scannerKey;
        this.location = null;
        this.shipment = data.shipment;
        this.changeDetectorRef.markForCheck();
        this.getShipment();
        this.getLocationTypes();
        this.getLocationsByOrder();

        if (this.showFreeBoxes) {
            this.getFreeBoxes();
            this.result.emit({
                action: "result",
                value: this.warehouseOrderId
            });
            this.soundsService.textToSpeech("Please fulfill missing boxes");
        } else {
            this.result.emit({
                action: "result",
                value: {warehouseOrderId: this.warehouseOrderId, showFreeBoxes: true}
            });
            this.soundsService.textToSpeech("Please scan location");
        }
        this.locationFrom.get("warehouse_location_type_id").valueChanges
            .pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe((): void => {
            if (this.newLocationNameInput) {
                this.newLocationNameInput.nativeElement.focus();
            }
        });

        this.searchInputRef.nativeElement.focus();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
